import { toJS } from 'mobx'
import { getRoot } from 'mobx-state-tree'

export const accountStoreViews = self => ({
  get authenticated() {
    return false
  },
  get cmsUsername() {
    return self.user.username
  },
  get cmsName() {
    if (self.user?.firstName !== null) {
      return self.user?.firstName
    } else {
      return "unknown"
    }
  },
  get userRole() {
    if (self.user?.role !== null) {
      if (self.user.role === "MERCHANT_MANAGER") {
        return "Merchant manager"
      }
      if (self.user.role === "SUPPORT") {
        return "Support"
      }
      if (self.user.role === "ADMIN") {
        return "Admin"
      } else {
        return "unknown"
      }
    } else  {
    return "unknown"
    }
  },
  get isUserMerchant() {
    if (self.user?.role !== null) {
      if (self.user.role === "MERCHANT_MANAGER") {
        return true
      } else {
        return false
      }
    } else  {
    return false
    }
  },
  get isUserAdminOrSupport() {
    if (self.user?.role !== null) {
      if (self.isUserSupport || self.isUserAdmin) {
        return true
      } else {
        return false
      }
    } else  {
    return false
    }
  },
  get isUserAdmin() {
    if (self.user?.role !== null) {
      if (self.user?.role === "ADMIN") {
        return true
      } else {
        return false
      }
    } else  {
    return false
    }
  },
  get isUserSupport() {
    if (self.user?.role !== null) {
      if (self.user?.role === "SUPPORT") {
        return true
      } else {
        return false
      }
    } else  {
    return false
    }
  },
  get authStore() {
    return getRoot(self).authStore
  },
  get isUserImpersonating() {
    if (self.isUserAdminOrSupport && self.authStore.managingStore !== null) {
      return true
    } else  {
    return false
    }
  },
  get isSupportImpersonating() {
    if (self.isUserSupport && self.authStore.managingStore !== null) {
      return true
    } else  {
    return false
    }
  },
  get isAdminImpersonating() {
    if (self.isUserAdmin && self.authStore.managingStore !== null) {
      return true
    } else  {
    return false
    }
  },
  get isUserImpersonatingOrMerchant() {
    if (self.isUserImpersonating || self.isUserMerchant) {
      return true
    } else  {
    return false
    }
  }
})