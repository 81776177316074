import { toast as toaster } from 'react-toastify';

const toasterGlobalSettings = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

export const toastToSuccess = (content) => {
  toaster.success( content.title, toasterGlobalSettings );
}

export const toastToError = (content) => {
  toaster.error( content.title, toasterGlobalSettings );
}

export const toastToInfo = (content) => {
  toaster.info( content.title, toasterGlobalSettings );
}

export const toastToWarning = (content) => {
  toaster.warning( content.title, toasterGlobalSettings );
}

export const toastToDefault = (content) => {
  toaster( content.title, toasterGlobalSettings );
}

