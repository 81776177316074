import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedOnlyAdmin = (props) => {

  const { authStore, accountStore } = props.store

  if (!accountStore.isUserAdmin) return <Navigate to="/off-limits" replace={true} />

  return props.children
}

export default inject('store')(observer(ProtectedOnlyAdmin))


