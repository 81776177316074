import { getRoot } from "mobx-state-tree"
import { convertToTime, convertToSimpleTimeStringForDispaly } from "../../../services/time-service"
import dayjs from 'dayjs';

export const holidayHoursStoreViews = self => ({
  get openingHoursFactory() {
    return getRoot(self).openingHoursFactory
  },
  get holidayHoursFactory() {
    return getRoot(self).holidayHoursFactory
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get isSetTitleError() {
    if (self.titleError) {
        return true
    } else {
        return false
    }
  },
  get setOpenHourFormated() {
    if (self.setOpenHour !== null) {
      return dayjs(convertToTime(self.setOpenHour))
    }
  },
  get setCloseHourFormated() {
    if (self.setCloseHour !== null) {
      return dayjs(convertToTime(self.setCloseHour))
    }
  },
  get showClosingTime() {
    if (self.setCloseHour !== null) {
      return convertToSimpleTimeStringForDispaly(convertToTime(self.setCloseHour))
    }
  },
  get showOpenningTime() {
    if (self.setOpenHour !== null) {
      return convertToSimpleTimeStringForDispaly(convertToTime(self.setOpenHour))
    }
  },
})