import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_login = async (data) => {
  try {
    const res = await axios.post(`${API}/cms/login`, data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': BASIC_AUTH
      }
    })
    return res
    
  } catch (err) {
    return _throw(err)
  }
}

export const api_register = async (data) => {
  try {
    const res = await axios.post(`${API}/cms/register`, data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': BASIC_AUTH
      }
    })
    return res
    
  } catch (err) {
    return _throw(err)
  }
}

export const api_complete_registration = async (data, token) => {
  try {
    const res = await axios.post(`${API}/merchant`, data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': token
      }
    })
    return res
    
  } catch (err) {
    return _throw(err)
  }
}

export const api_check_invite_token = async (token) => {
  try {
    const res = await axios.post(`${API}/cms/auth/invite/${token}`, {}, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': BASIC_AUTH
      }
    })
    return res
    
  } catch (err) {
    return _throw(err)
  }
}