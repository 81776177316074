import { getRoot } from "mobx-state-tree"
import dayjs from "dayjs"
import { convertToTime } from "../../../services/time-service"

export const systemSettingsStoreViews = self => ({   
    get token() {
        return getRoot(self).authStore.token
      },
      get usersFactory() {
        return getRoot(self).usersFactory
      },
      get authStore() {
        return getRoot(self).authStore
      },
      get merchantId() {
        return getRoot(self).authStore.managingStore.id
      },
      get setOpenHourFormated() {
        if (self.DEFAULT_OPEN_TIME !== null) {
          return dayjs(convertToTime(self.DEFAULT_OPEN_TIME))
        }
      },
      get setCloseHourFormated() {
        if (self.DEFAULT_CLOSE_TIME !== null) {
          return dayjs(convertToTime(self.DEFAULT_CLOSE_TIME))
        }
      },
  })