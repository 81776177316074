import { types } from "mobx-state-tree"
import * as actions from './EffectActions'
import Asset from "../asset/Asset"

const Effect = types
  .model('Effect', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    icon: types.maybeNull(Asset),
    cover: types.maybeNull(Asset),
  })
  .views(self => ({

  }))
  .actions(actions.effectActions)

export default Effect