import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_effect_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_effect = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/effects`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_effect = async (data, effectId, token) => {
  try {
    const res = await axios.put(`${API}/cms/effects/${effectId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_product_effects = async (data, token) => {
  try {
    const res = await axios.put(`${API}/cms/toggl-effect`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_all_categories_for_merchant = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_all_effects = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/effects`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

