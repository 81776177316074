import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './driversStoreActions'
import * as views from './driversStoreViews'

const DriversStore = types
  .model('DriversStore', {
    drivers: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    allDrivers: types.maybeNull(types.number),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(views.driversStoreViews)
  .actions(actions.driversStoreActions)

export default DriversStore