import { getRoot } from "mobx-state-tree"

export const driversStoreViews = self => ({   
    get token() {
        return getRoot(self).authStore.token
      },
      get usersFactory() {
        return getRoot(self).usersFactory
      },
      get authStore() {
        return getRoot(self).authStore
      },
      get merchantId() {
        return getRoot(self).authStore.managingStore.id
      },
  })