import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { api_post_coupon, api_update_coupon } from "../../../queries/coupons"
import { api_post_notification } from "../../../queries/notifications"
export const singleNotificationActions = self => ({
  validTitle() {
    if (self.title === null || self.title.length < 1) self.set('titleError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('titleError', null);
  },
  validText() {
    if (self.text === null || self.text.length < 1) self.set('textError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('textError', null);
  },
  clearInputs() {
    self.set('titleError', null);
    self.set('textError', null);
  },
  

async sendNotification() {
  try {
    self.set('loading', true)

    let dataIdObject = {}
    if (self.type === 'PROMOTION') {
      dataIdObject = { dataId: self.selectedCoupon?.id }
    } else if (self.type === 'DISPENSARY_OPEN') {
      dataIdObject = { 
        dataId: self.selectedMerchant?.id,
        pastDayOnly: self.pastDayOnly
      }
      
    }
    

    const data = {
      title: self.title,
      text: self.text,
      type: self.type,
      ...dataIdObject
    }
    const res = await api_post_notification(data, self.token)

    if (res.error) throw res

    //const ids = await self.categoriesFactory.addUpdateCategories([res.data])
    self.set('loading', false)
    toastToSuccess({
      title: `Notification was sent to ${res.data.data.notificationsSent} users`
    })
    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.errorNotificationSend)
    console.log(err)
  }
},

  set(key, value) {
    self[key] = value
  },
  cleanForm() {
    self.set('title', null)
    self.set('text', null)
    self.set('selectedCoupon', null)

    self.clearInputs()
  },
})