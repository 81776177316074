import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import Asset from "../../models/asset/Asset"
import { api_post_product, api_get_product_by_id, api_update_product, } from "../../../queries/products"
import { api_get_merchant_storefront, api_update_merchant_storefront } from "../../../queries/merchants"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { parse } from "date-fns"
import { api_post_effect, api_update_effect } from "../../../queries/effects"
import { api_update_category, api_post_category, api_delet_category } from "../../../queries/categories"
import { api_post_coupon, api_toggle_coupon_status, api_update_coupon } from "../../../queries/coupons"
import { convertToCents, convertToDollars } from "../../../services/currency-service"
import { _throw } from "../../../utils/error-service"
import { api_get_users_paginate } from "../../../queries/users"

export const singleCouponActions = self => ({
  validTitle() {
    if (self.title === null || self.title.length < 1) self.set('titleError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('titleError', null);
  },
  validCoupon() {
    if (self.coupon === null || self.coupon.length < 1) self.set('couponError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('couponError', null);
  },
  validMinimumOrderSize() {
    if (self.minimumOrderSize === null || self.minimumOrderSize < 0) self.set('minimumOrderSizeError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('minimumOrderSizeError', null);
  },
  validValue() {
    if (self.value === null || self.value < 0) self.set('valueError', ERRORS.ITEM_TITLE_ERROR);
    else self.set('valueError', null);
  },
  clearInputs() {
    self.set('titleError', null);
    self.set('couponError', null);
    self.set('minimumOrderSizeError', null);
    self.set('valueError', null);
  },
  

  createAndUpdateIcon(newAsset) {
    if (newAsset !== null ) {
    const asset = Asset.create()
    self.set('icon', asset)
    self.icon.update(newAsset)
    } else {
      return false
    }
  },
  createAndUpdateAsset(newAsset) {
    if (newAsset !== null) {
    const asset = Asset.create()
    self.set('asset', asset)
    self.asset.update(newAsset)
    } else {
      return false
    }
  },

async addCoupon() {
  try {
    self.set('loading', true)

    const data = {
      title: self.title,
      coupon: self.coupon,
      minimumOrderSize: convertToCents(self.minimumOrderSize),
      value: convertToCents(self.value),
      type: "FIXED_AMOUNT_OFF",
      restrictedToUser: null,
      usesPerUser: 100,
      status: self.status,

    }
    const res = await api_post_coupon(data, self.token)

    if (res.error) throw res

    //const ids = await self.categoriesFactory.addUpdateCategories([res.data])
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successProductCreated)
    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async updateCoupon() {
  try {
    self.set('loading', true)

    const data = {
      title: self.title,
      coupon: self.coupon,
      minimumOrderSize: convertToCents(self.minimumOrderSize),
      value: convertToCents(self.value),
      type: "FIXED_AMOUNT_OFF",
      restrictedToUser: null,
      usesPerUser: 100,
      status: self.status,
    }
    const res = await api_update_coupon(data, self.selectedCoupon?.id, self.token)

    if (res.error) throw res

    // const ids = await self.categoriesFactory.addUpdateCategories([res.data])
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successProductCreated)
    // return res

    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async toggleStatus() {
  try {
    self.set('loading', true)

    const res = await api_toggle_coupon_status(self.selectedCoupon?.id, self.token)

    if (res.error) throw res

    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successProductUpdate)
    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},

async searchUsers(query) {
  try {
    self.set('loading', true)
    const res = await api_get_users_paginate(self.token, 1, 10, query)

    if (res.error) throw res

    const ids = await self.usersFactory.addUpdateUsers(res.rows)
    self.set('searchedUsers', ids)
    self.set('loading', false)
    return res.data
  } catch (err) {
    console.log(err)
    return _throw(err)
  }
},

  set(key, value) {
    self[key] = value
  },
  setCouponForEdit(coupon) {

    self.set('selectedCoupon', coupon)
    self.set('title', self.selectedCoupon?.title)
    self.set('coupon', self.selectedCoupon?.coupon)
    self.set('status', self.selectedCoupon?.status)
    self.set('minimumOrderSize', convertToDollars(self.selectedCoupon?.minimumOrderSize))
    self.set('value', convertToDollars(self.selectedCoupon?.value))


    //self.createAndUpdateAsset(self.selectedEffect.cover)
  },
  cleanForm() {
    self.set('title', null)
    self.set('coupon', null)
    self.set('minimumOrderSize', null)
    self.set('value', null)

    self.clearInputs()
  },
  async deleteCategory() {
    try {
      self.set('loading', true)

      const res = await api_delet_category(self.selectedCategory?.id, self.token)

      if (res.error) throw res
      
      self.set('loading', false)
      return res.data

    } catch (err) {
      toastToError(ToasterMsgs.genericError)
      self.set('loading', false)
      console.log(err)
      return err
    }
  },
})