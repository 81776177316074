import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_post_notification = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/send-notifications`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


