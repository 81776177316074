import { types } from "mobx-state-tree"
import * as actions from './feedbacksFactoryActions'
import * as views from './feedbacksFactoryViews'
import Feedback from "../../models/feedback/Feedback"

const FeedbacksFactory = types
  .model('FeedbacksFactory', {
    feedbacks: types.optional(
      types.array(Feedback), []
    )
  })
  .views(views.feedbacksFactoryViews)
  .actions(actions.feedbacksFactoryActions)

export default FeedbacksFactory