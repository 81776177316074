import { types } from "mobx-state-tree";
import * as actions from './singleCouponActions';
import * as views from './singleCouponViews';
import Coupon from "../../models/coupon/coupon";
import User from "../../models/user/User";

const SingleCouponStore = types
  .model('SingleCouponStore', {
    selectedCoupon: types.maybeNull(
      types.reference(Coupon)
    ),

    searchedUsers: types.array(types.reference(User)),

    title: types.maybeNull(types.string),
    coupon: types.maybeNull(types.string),
    minimumOrderSize: types.maybeNull(types.number),
    value: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    usesPerUser: types.maybeNull(types.string),
      
    titleError: types.maybeNull(types.string),
    couponError: types.maybeNull(types.string),
    minimumOrderSizeError: types.maybeNull(types.string),
    statusError: types.maybeNull(types.string),
    typeError: types.maybeNull(types.string),
    usesPerUserError: types.maybeNull(types.string),
    valueError: types.maybeNull(types.string),

    loading: false,
  })
  .views(views.singleCouponViews)
  .actions(actions.singleCouponActions);

export default SingleCouponStore;


