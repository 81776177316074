// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import React from 'react'

const DatePickerCustom = (props) => {
  return (
    <DatePicker 
      value={props.value}
      onChange={props.onChange}
      label={props.label}
    />

  )
}

export default DatePickerCustom