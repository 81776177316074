import { types } from 'mobx-state-tree'
import * as views from './importMenuStoreViews'
import * as actions from './importMenuStoreActions'
import HolidayHours from '../../models/holidayHours/HolidayHours'
import { set } from 'nprogress'
import { key } from 'localforage'
import { toast } from 'react-toastify'

export const IMPORT_STATE = {
  UPLOAD: 'UPLOAD',
  MAPPING: 'MAPPING',
  IMPORTING: 'IMPORTING',
  COMPLETE: 'COMPLETE'
}

const MenuMappings = types.model('MenuMappings', {
  originalName: types.string,
  mappedTo: types.string,
  active: types.boolean
}).actions(self => ({
  clearMappedTo() {
    self.setMappedTo('')
  },
  setMappedTo(value) {
    if (value === "NONE") {
      self.clearMappedTo()
      self.set("active", false) 
    } else {
      self.mappedTo = value
    }

  },
  setActive() {
    self.active = true
  },
  setActiveFalse() {
    self.active = false
  },
  toggleActive() {
    if (!self.active && self.mappedTo === "") {
      return toast.error("Please map this column to a data type, choose type on the the dropdown")
    } 
    self.active = !self.active
  },
  set(key , value) {
    self[key] = value
  }
}))

const ImportMenuStore = types
  .model('holidayHoursStore', {

    file: types.maybeNull(types.frozen()),
    rawFile: types.frozen(),
    headers: types.optional(
      types.array(MenuMappings), []
    ),
    separator: types.optional(types.string, ';'),

    state: types.optional(types.string, IMPORT_STATE.UPLOAD),

    loading: false

  })
  .views(views.importMenuStoreViews)
  .actions(actions.importMenuStoreActions)

export default ImportMenuStore
