import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_category_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_all_categories_for_merchant = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_all_categories = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/categories`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_category = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/categories`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_category = async (data, categoryId, token) => {
  try {
    const res = await axios.put(`${API}/cms/categories/${categoryId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delet_category = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

