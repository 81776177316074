import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_feedbacks = async (token, merchantId) => {
  try {
    const res = await axios.get(`${API}/cms/ratings/${merchantId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}



