
export const locationActions = self => ({
  update(l) {
    self.set('title', l.title)
    self.set('lat', l.lat)
    self.set('lng', l.lng)
    self.set('address', l.address)
    self.set('buildingNumber', l.buildingNumber)
    self.set('streetName', l.streetName)
    self.set('streetNumber', l.streetName)
    self.set('street', l.street)
    self.set('districtName', l.districtName)
    self.set('city', l.city)
    self.set('post', l.post)
    self.set('state', l.state)
    self.set('country', l.country)
    self.set('floor', l.floor)
    self.set('instructions', l.instructions)
    self.set('unit', l.unit)
  },
  set(key, value) {
    self[key] = value
  }
})