import { api_get_all_categories, api_get_all_categories_for_merchant } from "../../../queries/categories"
import { api_get_all_effects } from "../../../queries/effects"
import { toJS } from "mobx"

export const effectsStoreActions = self => ({   
  async getEffects() {
    try {
        self.set('loading', true)
        const res = await api_get_all_effects(self.token)

        if (res.error) throw res

        if (res.data.count !== 0) {
        self.set('allEffects', res.data.count)
        }
        
        const ids = await self.effectsFactory.addUpdateEffects(res.data.rows)
        self.set('effects', ids)
        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
},
async getEffectsForMerchant() {
  try {
      self.set('loading', true)
      const res = await api_get_all_categories_for_merchant(self.token, self.merchantId)

      if (res.error) throw res

      if (res.data.count !== 0) {
      self.set('allEffects', res.data.count)
      }
      const ids = await self.effectsFactory.addUpdateEffects(res.data.rows)
      self.set('effects', ids)
      self.set('loading', false)
      return res.data

  } catch (err) {
      self.set('loading', false)
      console.log(err)
  }
},
  set(key, value) {
    self[key] = value
  }
})