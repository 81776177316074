import { types } from 'mobx-state-tree'
import * as views from './statisticsStoreViews'
import * as actions from './statisticsStoreActions'

const StatisticsStore = types
  .model('statisticsStore', {
    orders: types.optional(types.number, 0),
    products: types.optional(types.number, 0),
    revenue: types.optional(types.number, 0),
    revenueLastWeek: types.optional(types.number, 0),
    reviews: types.optional(types.number, 0),
    ordersByWeeks: types.frozen(),
    newOrderStatus: types.maybeNull(types.string)
  })
  .views(views.statisticsStoreViews)
  .actions(actions.statisticsStoreActions)

export default StatisticsStore
