import find from 'lodash/find';
import { api_get_coupon_by_id } from '../../../queries/coupons';
import { _add_update_objects } from '../../../services/factories-service';

export const couponsFactoryActions = self => ({
  addUpdateCoupons(coupons) {
    return _add_update_objects(coupons, self.coupons, self.parseCoupon);
  },
  addUpdateCoupon(p) {
    const ids = self.addUpdateCoupons([p]);
    return ids[0];
  },  
  parseCoupon(p) {
    return ({
      ...p,
    });
  },
//   async getCoupon(mId) {
//     try {
//       const id = parseInt(mId);

//       let coupon = find(self.coupons, p => p.id === id);
//       if (coupon) return coupon;

//       const res = await self.fetch(id);
//       if (res.error) throw res;

//       return find(self.coupons, p => p.id === id);
//     } catch (err) {
//       return {
//         ...err,
//         error: true
//       };
//     }
//   },
//   async fetch(id) {
//     try {
//       const res = await api_get_coupon_by_id(id);
//       if (res.error) throw res;

//       self.addUpdateCoupon(res);

//       return res;
//     } catch (err) {
//       return err;
//     }
//   },
});
