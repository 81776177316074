export const couponActions = self => ({
    update(c) {
      self.set('title', c.title);
      self.set('coupon', c.coupon);
      self.set('createdAt', c.createdAt);
      self.set('updatedAt', c.updatedAt);
      self.set('minimumOrderSize', c.minimumOrderSize);
      self.set('restrictedToUser', c.restrictedToUser);
      self.set('status', c.status);
      self.set('type', c.type);
      self.set('usesPerUser', c.usesPerUser);
      self.set('value', c.value);
    },
    set(key, value) {
      self[key] = value;
    }
  });
  