import { types } from "mobx-state-tree";
import Coupon from "../../models/coupon/coupon";
import * as actions from './couponsFactoryActions';
import * as views from './couponsFactoryViews';

const CouponsFactory = types
  .model('CouponsFactory', {
    coupons: types.optional(
      types.array(Coupon), []
    )
  })
  .views(views.couponsFactoryViews)
  .actions(actions.couponsFactoryActions);

export default CouponsFactory;
