
export const feedbackActions = self => ({
  update(r) {
    self.set('stars', r.stars)
    self.set('text', r.text)
    self.set('orderId', r.orderId)
    self.set('user', r.user)
    self.set('merchant', r.merchant)
  },
  set(key, value) {
    self[key] = value
  }
})