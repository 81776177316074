import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import Asset from "../../models/asset/Asset"
import { api_post_product, api_get_product_by_id, api_update_product, api_delet_product, api_update_product_status, api_ai_product_suggestions } from "../../../queries/products"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { api_update_product_effects } from "../../../queries/effects"

export const addUpdateProductStoreActions = self => ({
  resetProduct(id) {
    self.set('product', id)
  },
  validTitle() {
    if (self.title === null || self.title === '' || self.title.length < 1) self.set('titleError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('titleError', null)
  },
  validAsset() {
    if (self.asset === null) self.set('assetError', ERRORS.ASSET_ERROR) 
    else self.set('AssetError', null)
  },
  validDescription() {
    if (self.description === null || self.description.length < 1) self.set('descriptionError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('titleError', null)
  },
  validBasePrice() {
    if (self.basePrice === null || self.basePrice < 1 ) self.set('basePriceError', ERRORS.ITEM_BASE_PRICE_ERROR)
    else self.set('basePriceError', null)
  },
  validTax() {
    if (self.taxRate === null   ) self.set('taxError', ERRORS.ITEM_TAX_RATE_ERROR)
    else self.set('taxError', null)
  },
  validThc() {
    if (self.thc === null  ) self.set('thcError', ERRORS.ITEM_THC_ERROR)
    else self.set('thcError', null)
  },
  validCbd() {
    if (self.cbd === null  ) self.set('cbdError', ERRORS.ITEM_CBD_ERROR)
    else self.set('cbdError', null)
  },
  validStock() {
    if (self.stock === null || self.stock < 0 ) self.set('stockError', ERRORS.ITEM_AVAILABLE_ERROR)
    else self.set('stockError', null)
  },
  clearInputs() {
    self.set('titleError', null)
    self.set('descriptionError', null)
    self.set('basePriceError', null)
    self.set('taxError', null)
    self.set('thcError', null)
    self.set('cbdError', null)
    self.set('stockError', null)
    self.set('assetError', null)
  },
  clearForm() {
    self.set('title', null)
    self.set('description', null)
    self.set('basePrice', null)
    // self.set('tax', null)
    self.set('tax', 13.00)
    self.set('thc', null)
    self.set('cbd', null)
    self.set('sku', null)
    self.set('stock', null)
    self.set('category', self.firstCategoryInArray)
    self.set('asset', null)
    self.set('type', "HYBRID")
    self.set('effects', [])
  },
  setProductForEdit() {

    self.set('sku', self.selectedProduct.sku)
    self.set('title', self.selectedProduct.title)
    self.set('description', self.selectedProduct.description)
    self.set('basePrice', self.selectedProduct.basePrice)
        // self.set('tax', self.selectedProduct.tax)
        self.set('tax', 13.00)
    self.set('stock', self.selectedProduct.stock)
    self.set('thc', self.selectedProduct.thc)
    self.set('cbd', self.selectedProduct.cbd)
    self.set('category', self.categoryIdView)
    self.set('status', self.selectedProduct.status)
    self.set('type', self.selectedProduct.type)
    self.set('effects', Array.isArray(self.selectedProduct.effects) && self.selectedProduct.effects.length ? self.selectedProduct.effects.map(effect => effect.id) : [] )
    self.createAndUpdateAsset(self.selectedProduct.assets)

    self.cleanAiSuggestions()
  },
  cleanAiSuggestions() {
    self.set('aiTitle', null)
    self.set('aiDescription', null)
    self.set('aiCategory', null)
    self.set('aiEffects', [])
    self.set('aiStrain', null)
    self.set('aiThc', null)
    self.set('aiCbd', null)
  },
  async addItem() {
      try {
        self.set('loading', true)

        const data = {
          title: self.title,
          description: self.description,
          basePrice: self.basePrice,
          tax: self.tax,
          thc: self.thc,
          cbd: self.cbd,
          stock: self.stock,
          merchantId: self.merchantId,
          categoryId: self.category?.id,
          effects: self.selectedEffects,
          sku: self.sku,
          type: self.type === null ? "UNDEFINED" : self.type,
          assets: [{assetId: self.asset.id}],
        }
        const res = await api_post_product(data, self.token)

        if (res.error) throw res

        const ids = await self.productsFactory.addUpdateProducts([res.data])

        self.set('selectedProduct', ids[0])
        await self.updateProductEffect()

        self.set('loading', false)
        toastToSuccess(ToasterMsgs.successProductCreated)
        return res

      } catch (err) {
        self.set('loading', false)

        if (err.response?.data?.message) {
          toastToError({title: err.response?.data?.message})
        } else {
          toastToError(ToasterMsgs.genericError)
        }
        
        console.log(err)
      }
  },
  createAndUpdateAsset(newAsset) {
    if (newAsset !== null || newAsset.length === 0) {
    const asset = Asset.create()
    self.set('asset', asset)
    self.asset.update(newAsset[0])
    } else {
      return false
    }
  },
  async updateItem() {
    try {
      self.set('loading', true)

      const data = {
        title: self.title,
        description: self.description,
        basePrice: self.basePrice,
        tax: self.tax,
        thc: self.thc,
        sku: self.sku,
        cbd: self.cbd,
        stock: self.stock,
        categoryId: self.category?.id,
        type: self.type === null ? "UNDEFINED" : self.type,
        assets: [{assetId: self.asset.id}],
      }

      const res = await api_update_product(data, self.token, self.selectedProduct.id)

      if (res.error) throw res

      await self.updateProductEffect()

      const ids = await self.productsFactory.addUpdateProducts([res.data])
      self.set('loading', false)
      toastToSuccess(ToasterMsgs.successProductUpdate)
      return res.data

    } catch (err) {
      self.set('loading', false)
      if (err.response?.data?.message) {
        toastToError({title: err.response?.data?.message})
      } else {
        toastToError(ToasterMsgs.genericError)
      }
      console.log(err)
    }
},

async updateProductEffect() {
  try {
    self.set('loading', true)

    const data = {
      productId: self.selectedProduct?.id,
      effectIds: self.selectedEffectsIds
    }

    const res = await api_update_product_effects(data, self.token)

    if (res.error) throw res

    const ids = await self.effectsFactory.addUpdateEffects(res.data.rows)
    self.set('loading', false)
    //toastToSuccess(ToasterMsgs.successProductUpdate)
    return res.data

  } catch (err) {
    self.set('loading', false)
    //toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
  async uploadAsset(file, preview) {
    self.set('loadingAsset', true)
    try {
      const asset = Asset.create()
      self.set('asset', asset)

      const res = await asset.upload(
        self.token,
        file,
        preview,
        'PRODUCT_PHOTO',
        self.merchantId
      )

      if (res.error) throw res

      self.set('loadingAsset', false)
      return res.data
    } catch (err) {
      self.set('loadingAsset', false)
      console.log(err)
    }
  },
  async getAndEditProduct(id) {
    try {
      self.set('loading', true)
      const res = await api_get_product_by_id(id, self.token)

      if (res.error) throw res

      const ids = await self.productsFactory.addUpdateProducts([res.data])
      self.clearInputs()
      self.set('selectedProduct', ids[0])
      self.setProductForEdit()

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
    }
  },
  async deleteProduct() {
    try {
      self.set('loading', true)

      const res = await api_delet_product(self.selectedProduct?.id, self.token)

      if (res.error) throw res
      
      self.set('loading', false)
      return res.data

    } catch (err) {
      toastToError(ToasterMsgs.genericError)
      self.set('loading', false)
      console.log(err)
      return err
    }
  },
  async toggleProductStatus() {
    try {

      self.set('publishing', true)

      const res = await api_update_product_status(self.token, self.selectedProduct?.id, {
        status: self.status === 'ACTIVE' ? 'DRAFT' : 'ACTIVE'
      })

      if (res.error) {
        throw res.response.data
      }

      const ids = await self.productsFactory.addUpdateProducts([res.data])
      toastToSuccess(ToasterMsgs.successProductUpdate)

      self.set("status", res.data.status)

      self.set('publishing', false)

    } catch (err) {
      console.log(err)

      let message = err.message || "Something went wrong"

      toastToError({title: message})

      self.set('publishing', false)

      return err
    }
  },
  async getAiSuggestions() {
    try {
      self.set('aiLoading', true)
      const res = await api_ai_product_suggestions(self.token, self.selectedProduct?.id)

      self.set('aiTitle', res.data.aiTitle)
      self.set('aiDescription', res.data.aiDescription)
      self.set('aiCategory', res.data.aiCategory)
      self.set('aiEffects', res.data.aiEffects)
      self.set('aiStrain', res.data.aiStrain)
      self.set('aiThc', res.data.aiThc)
      self.set('aiCbd', res.data.aiCbd)

      if (res.error) throw res

      self.set('aiLoading', false)

      return res.data
    } catch (err) {
      self.set('aiLoading', false)
      return err
    }
  },
  set(key, value) {
    self[key] = value
  }
})