import { api_get_opening_hours, api_update_opening_hours, api_get_holiday_hours, api_create_holiday } from "../../../queries/openingHours";
import { toJS } from "mobx";
import { convertToTime, localTime, UTCTime } from "../../../services/time-service";
import { ERRORS } from "../../../constants/strings";
import { ToasterMsgs } from "../../../constants/toasterMsgs";
import { toastToError, toastToSuccess } from "../../../services/notification-service";

export const openingHoursStoreActions = self => ({   

  async getStoreOpeningHours() {
    try {
        self.set('loading', true)
        const res = await api_get_opening_hours(self.token, self.merchantId)

        if (res.error) throw res

        const ids = await self.openingHoursFactory.addUpdateOpeningHours(res.data)
        self.set('allDaysInWeek', ids)
        self.set('loading', false)

        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
},
async updateWorkingHours() {
  try {
    self.set('loading', true)

    const data = {
      openHour: self.setOpenHour,
      closeHour: self.setCloseHour,
      closed: self.setClosed,
    }

    const res = await api_update_opening_hours(data, self.token, self.selectedDate.id)

    if (res.error) throw res

    self.getStoreOpeningHours()
    
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successWorkingHoursUpdate)
    return res.data

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async getAndEditDate(id) {

  self.getStoreOpeningHours()
  self.set('selectedDate', id)
  self.setWorkingHouhrForEdit()
},
setWorkingHouhrForEdit() {
  self.set('setOpenHour', self.selectedDate.openHour)
  self.set('setCloseHour', self.selectedDate.closeHour)
  self.set('setClosed', self.selectedDate.closed)

},
validSetTitle() {
  if (self.title === null || self.title === '' || self.title.length < 1) self.set('setTitleError', ERRORS.ITEM_TITLE_ERROR) 
  else self.set('setTitleError', null)
},
  set(key, value) {
    self[key] = value
  }
})