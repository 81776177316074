import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_coupon_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_all_coupons = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/promotions`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_coupon = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/promotion`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_coupon = async (data, couponId, token) => {
  try {
    const res = await axios.put(`${API}/cms/promotion/${couponId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_toggle_coupon_status = async (couponId, token) => {
  try {
    const res = await axios.put(`${API}/cms/toggle-promotion-status/${couponId}`, null, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delet_coupon = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

