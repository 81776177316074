import { getRoot } from "mobx-state-tree"
import { OPEN_STATUS } from "../../../constants/types"



export const statisticsStoreViews = self => ({
  get formattedRevenue() {
    return `$${self.revenue.toFixed(2)}`
  },
  get formattedWeeklyGrowthDollar() {
    if (self.weeklyGrowthDollar === null) return `$0.00`
    else if (self.weeklyGrowthDollar >= 0) return `+ $${self.weeklyGrowthDollar.toFixed(2)}`
    else if (self.weeklyGrowthDollar < 0) return `- $${Math.abs(self.weeklyGrowthDollar).toFixed(2)}`
  },
  get formattedWeeklyGrowthPercentage() {
    if (self.weeklyGrowthPercentage === null) return `0.00%`
    else if (self.weeklyGrowthPercentage >= 0) return `+ ${self.weeklyGrowthPercentage.toFixed(2)}%`
    else if (self.weeklyGrowthPercentage < 0) return `- ${Math.abs(self.weeklyGrowthPercentage).toFixed(2)}%`
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get openStatusFormatted() {
    if (self.openStatus === OPEN_STATUS.CLOSED) return "Closed due to Opening Hours"
    else if (self.openStatus === OPEN_STATUS.OPEN && self.newOrderStatus !== "ACTIVE") return "Orders are temporary paused"
    else return "Dispensary open"
  },
  get openStatusFormattedShort() {
    if (self.openStatus === OPEN_STATUS.CLOSED) return "CLOSED"
    else if (self.openStatus === OPEN_STATUS.OPEN && self.newOrderStatus !== "ACTIVE") return "PAUSED"
    else return "OPEN"
  }

})