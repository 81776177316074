import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_post_product = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/products`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_product = async (data, token, productId) => {
  try {
    const res = await axios.put(`${API}/cms/products/${productId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_product_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/cms/products/product-by-id/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_products_by_merchant_id_paginate = async (token, page, row, merchantId, searchQuerry, categoryId, order, statuses, inStockOnly) => {
  try {

    let orderQ = ``
    if (order) orderQ = `&order=${order}`

    let statusQ = `ACTIVE,DRAFT`
    if (statuses && statuses.length > 0) statusQ = statuses.join(',')
    
    let minStockWhere = ``
    if (inStockOnly) minStockWhere = `&minStock=1`
    else minStockWhere = `&minStock=0`

    const res = await axios.get(`${API}/cms/products/${merchantId}?page=${page}&items=${row}&query=${searchQuerry}&category=${categoryId}&status=${statusQ}${orderQ}${minStockWhere}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_products_by_merchant_id_all = async (token, merchantId) => {
  try {
    const res = await axios.get(`${API}/cms/products/${merchantId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_delet_product = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/products/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_product_status = async (token, id, data) => {
  try {
    const res = await axios.put(`${API}/cms/products/update-status/${id}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_import_menu = async (token, data, merchantId) => {
   try {
    const res = await axios.post(`${API}/cms/products/import-menu/${merchantId}`, data, {
      headers: {
        'Authorization': token
      }
    })

    return res.data
   } catch (err) {
    console.log(err)
    return _throw(err)
   }
}

export const test_api_get_products_by_merchant_id_paginate = async (token, page, row, merchantId, searchQuerry) => {
  try {
    const res = await axios.get(`${API}/cms/products/${merchantId}?page=${page}&items=${row}&query=${searchQuerry}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_ai_product_suggestions = async (token, productId) => {
  try {
    const res = await axios.get(`${API}/cms/products/${productId}/ai-suggest`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_toggle_editors_pick = async (token, productId) => {
  try {
    const res = await axios.put(`${API}/cms/products/${productId}/toggle-editors-pick`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}