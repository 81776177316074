
export const effectActions = self => ({
  update(c) {
    self.set('title', c.title)
    self.set('description', c.description)
    self.set('icon', c.icon)
    self.set('cover', c.cover)
  },
  set(key, value) {
    self[key] = value
  }
})