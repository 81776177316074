import { api_get_products_by_merchant_id_paginate } from "../../../queries/products"
import { api_get_orders_by_merchant_id_paginate, api_update_order } from "../../../queries/orders"
import { toJS } from "mobx"
import { api_get_orders_on_budz_paginate } from "../../../queries/orders"
import { toastToSuccess, toastToError } from "../../../services/notification-service"
import { ToasterMsgs } from "../../../constants/toasterMsgs"

export const supportMerchantOrdersActions = self => ({
    async getMerchantOrdersPaginate(page, row) {
        try {
            self.set('loading', true)
            const res = await api_get_orders_on_budz_paginate(self.token, page, row, self.searchString, self.status)
            if (res.error) throw res

            if (res.count !== 0) {
            self.set('allOrders', res.data.count)
            }
            const ids = await self.ordersFactory.addUpdateOrders(res.data.rows)
            self.set('orders', ids)
            self.set('loading', false)
            return res.data

        } catch (err) {
            self.set('loading', false)
            console.log(err)
        }
    },

    async updateOrderMerchantPaidStatus(order) {
        try {
          self.set('loading', true)
    
          const data = {
            order: {
                merchantPaid: order.merchantPaid,
            }
          }
    
          const res = await api_update_order(data, self.token, order.id)
    
          if (res.error) throw res
          //toastToSuccess(ToasterMsgs.successStorefrontUpdated)
          self.set('loading', false)
          return res.data
    
        } catch (err) {
          self.set('loading', false)
          order.set('merchantPaid', !order.merchantPaid)
          toastToError(ToasterMsgs.genericError)
          console.log(err)
        }
    },
    async updateOrderDriverPaidStatus(order) {
        try {
          self.set('loading', true)
    
          const data = {
            order: {
                driverPaid: order.driverPaid,
            }
          }
    
          const res = await api_update_order(data, self.token, order.id)
    
          if (res.error) throw res
          //toastToSuccess(ToasterMsgs.successStorefrontUpdated)
          self.set('loading', false)
          return res.data
    
        } catch (err) {
          self.set('loading', false)
          order.set('merchantPaid', !order.merchantPaid)
          toastToError(ToasterMsgs.genericError)
          console.log(err)
        }
    },
    
    querrySearch(value) {
        self.set('searchInput', value)
        self.getMerchantOrdersPaginate(1, self.currentRow)
      },
    set(key, value) {
        self[key] = value
      }
})