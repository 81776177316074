import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"
export const couponsStoreViews = self => ({
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get couponsFactory() {
    return getRoot(self).couponsFactory
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  }
})