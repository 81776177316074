import { api_get_simple_stats } from "../../../queries/stats"


export const statisticsStoreActions = self => ({   

  async fetchSimpleStats() {
    try {
      const res = await api_get_simple_stats(self.merchantId, self.token)

      if (res.error) throw res

      self.setSimpleStats(res.data)

      return res.data
    } catch (err) {

      console.log(err)

    }
  },

  setSimpleStats(data) {
    self.set('orders', data.orders)
    self.set('revenue', data.revenue)
    self.set('products', data.products)
    self.set('reviews', data.reviews)
    self.set('openStatus', data.openStatus)
    self.set('newOrderStatus', data.newOrderStatus)
    self.set('ordersByWeeks', data.ordersByWeeks)
    self.set('weeklyGrowthDollar', data.weeklyGrowthDollar)
    self.set('weeklyGrowthPercentage', data.weeklyGrowthPercentage)
  },
  
  set(key, value) {
    self[key] = value
  }
})