import { types } from "mobx-state-tree"
import * as actions from './singleEffectActions'
import * as views from './singleEffectViews'
import Asset from "../../models/asset/Asset"
import Effect from "../../models/effect/Effect"

const SingleEffectStore = types
  .model('SingleEffectStore', {
    selectedEffect: types.maybeNull(
      types.reference(Effect)
    ),

    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),

    asset: types.maybeNull(types.optional(Asset, {})),
    icon: types.maybeNull(types.optional(Asset, {})),
      
    titleError: types.maybeNull(types.string),
    descriptionError: types.maybeNull(types.string),
    loading: false,
    loadingIcon: false,
    loadingAsset: false,
    loadingIconAsset: false,
  })
  .views(views.singleEffectViews)
  .actions(actions.singleEffectActions)

export default SingleEffectStore

