import { api_get_drivers } from "../../../queries/drivers"

export const driversStoreActions = self => ({   
  async getDrivers() {
    try {
      self.set('loading', true)
      const res = await api_get_drivers(self.token)

      if (res.error) throw res

      const ids = await self.usersFactory.addUpdateUsers(res.data.data)
      self.set('drivers', ids)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  set(key, value) {
    self[key] = value
  }
  })