import { getRoot } from 'mobx-state-tree'

export const authStoreViews = self => ({
    get accountStore() {
        return getRoot(self).accountStore
    },
    get categoriesStore() {
        return getRoot(self).categoriesStore
    },
    get effectsStore() {
        return getRoot(self).effectsStore
    },
    get authStore() {
        return getRoot(self).authStore
    },
    get usersFactory() {
        return getRoot(self).usersFactory
      },
    get merchantsFactory() {
        return getRoot(self).merchantsFactory
    },
    get rootStore() {
        return getRoot(self)
    },
    get isAuthenticated() {
        return self.token
    },
    get isMerchant() {
        if (self.accountStore.user?.managingStore) {
            return self.accountStore.user.managingStore
        } else {
            return false
        }
    },
    get isMerchantFullyOnboarded() {
        if (self.accountStore.user?.managingStore === null && self.accountStore.user?.role === "MERCHANT_MANAGER") {
            return false
        } else {
            return true
        }
    },
    get merchantName() {
        if (self.managingStore !== null) {
            return self.managingStore.title
        } else {
            return "Merchant"
        }
    },
    get valid() {
        self.validCompanyName()
        self.validLegalName()
        self.validSecondaryEmail()
        return ( self.secondaryEmailError || self.companyNameError || self.legalNameError ) === null
    },
    get isSecondaryEmailError() {
        if (self.secondaryEmailError) {
            return true
        } else {
            return false
        }
    },
    get isLegalNameError() {
        if (self.legalNameError) {
            return true
        } else {
            return false
        }
    },
    get isSecondaryEmailError() {
        if (self.secondaryEmailError) {
            return true
        } else {
            return false
        }
    },
    get isCompanyNameError() {
        if (self.companyNameError) {
            return true
        } else {
            return false
        }
    },
    get isCompanyNameError() {
        if (self.companyNameError) {
            return true
        } else {
            return false
        }
    }

  })