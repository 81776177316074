import { types } from "mobx-state-tree"
import Merchant from "../../models/merchant/Merchant"
import * as views from './merchantsStoreViews'
import * as actions from './merchantsStoreActions'

const MerchantsStore = types
  .model('MerchantsStore', {
    merchants: types.maybeNull(types.optional(
      types.array(types.reference(Merchant)), []
    )),
    selectedMerchant: types.maybeNull(
      types.reference(Merchant)
    ),
    allMerchantsCount: types.maybeNull(types.number),
  })
  .views(views.merchantsStoreViews)
  .actions(actions.merchantsStoreActions)

export default MerchantsStore