export const ToasterMsgs = {
  genericError: {
    title: "Something did not work!",
  },
  successProductUpdate: {
    title: "Item was updated!",
  },
  successProductDelete: {
    title: "Item was deleted!",
  },
  successProductCreated: {
    title: "Item was created!",
  },
  successWorkingHoursUpdate: {
    title: "Working hours were updated!",
  },
  successHolidayHoursUpdate: {
    title: "Holiday hours were updated!",
  },
  successHolidayHoursCreated: {
    title: "Holiday was created!",
  },
  driverCreated: {
    title: "Driver was created!",
  },
  successOrderCreated: {  
    title: "Order was created!",
  },
  successStorefrontUpdated: {  
    title: "Storefront was updated!",
  },
  successOrderAccepted: {
    title: "Order was accepted 🌿. Below you'll see assigned drivers."
  },
  successNotificationSend: {
    title: "Notification was send to all users."
  },
  errorNotificationSend: {
    title: "Notification was not send because of an error. Please talk to the IT before resending."
  }
} 
