import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"

export const storefrontViews = self => ({
  get fullPrice() {
    return 'US$' + self.quantity * self.product?.price
  },
  get valid() {
    self.validTitle()
    self.validDescription()
    self.validLegalName()
    self.validEmail()
    self.validPhone()
    self.validDeliveryCommision()
    self.validPickupCommision()
    self.validLicenceNumber()
    self.validtaxId()
    return ( 
      self.titleError || 
      self.descriptionError || 
      self.legalNameError ||
      self.emailError ||
      self.phoneError ||
      self.deliveryCommisionError ||
      self.licenceNumberError ||
      self.taxIdError
      ) === null
  },
  get isTitleError() {
    if (self.titleError) {
        return true
    } else {
        return false
    }
  },
  get isDescriptionError() {
    if (self.descriptionError) {
        return true
    } else {
        return false
    }
  },
  get isLegalNameError() {
    if (self.legalNameError) {
        return true
    } else {
        return false
    }
  },
  get isEmailError() {
    if (self.emailError) {
        return true
    } else {
        return false
    }
  },
  get isPhoneError() {
    if (self.phoneError) {
        return true
    } else {
        return false
    }
  },
  get isDeliveryCommissionError() {
    if (self.deliveryCommissionError) {
        return true
    } else {
        return false
    }
  },
  get isPickupCommisionError() {
    if (self.pickupCommisionError) {
        return true
    } else {
        return false
    }
  },
  get isLicenceNumberError() {
    if (self.licenceNumberError) {
        return true
    } else {
        return false
    }
  },
  get isTaxIdError() {
    if (self.taxIdError) {
        return true
    } else {
        return false
    }
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantStore() {
    return getRoot(self).merchantStore
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore?.id
  },
  get categoryIdView() {
    if (self.selectedProduct.category !== null) {
        return self.selectedProduct.category.id
    } else {
        return null
    }
  },
  get locationInsutrctions() {
    if (self.merchantStorefront?.location !== null) {
        return self.merchantStorefront?.location.instructions
    } else {
        return ''
    }
  },
})