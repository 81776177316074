import { getRoot } from "mobx-state-tree"

export const merchantsFactoryViews = self => ({
  get openingHoursFactory() {
    return getRoot(self).openingHoursFactory
  },
  get locationsFactory() {
    return getRoot(self).locationsFactory
  },
  get activeMerchants() {
    return self.merchants.filter(m => m.status === "ACTIVE")
  }
})