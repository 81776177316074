import { types } from "mobx-state-tree";
import * as actions from './CouponActions';
import * as views from './CouponViews';

const Coupon = types
  .model('Coupon', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    coupon: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    minimumOrderSize: types.maybeNull(types.number),
    restrictedToUser: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    usesPerUser: types.maybeNull(types.number),
    value: types.maybeNull(types.number),
  })
  .views(views.couponViews)
  .actions(actions.couponActions);

export default Coupon;
