import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './accountingStoreActions'
import * as views from './accountingStoreViews'
import Order from "../../models/order/Order";

const AccountingStore = types
  .model('AccountingStore', {
    orders: types.maybeNull(types.optional(
      types.array(types.reference(Order)), []
    )),
    ordersCount: types.maybeNull(types.number),

    query: types.maybeNull(types.string, ''),

    fromDate: types.optional(types.Date, new Date().setDate(new Date().getDate() - 90)),
    toDate: types.optional(types.Date, new Date()),
    unsettledSum: types.optional(types.string, ''),

    loading: false,

    limit: 50,
    page: 1
  })
  .views(views.accountingStoreViews)
  .actions(actions.accountingStoreActions)

export default AccountingStore