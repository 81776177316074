
export const deliveryTaskActions = self => ({
  update(r) {
    self.set('driverId', r.driverId)
    self.set('onFleetTaskId', r.onFleetTaskId)
    self.set('pickup', r.pickup)
    self.set('createdAt', r.createdAt)
    self.set('updatedAt', r.updatedAt)
  },
  set(key, value) {
    self[key] = value
  }
})