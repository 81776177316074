
export const openingHoursActions = self => ({
  update(oh) {
    self.set('openHour', oh.openHour)
    self.set('closeHour', oh.closeHour)
    self.set('dayOfTheWeek', oh.dayOfTheWeek)
    self.set('closed', oh.closed)
  },
  set(key, value) {
    self[key] = value
  }
})