import { getRoot } from "mobx-state-tree"

export const supportMerchantOrdersViews = self => ({
  get token() {
    return getRoot(self).authStore.token
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get ordersFactory() {
    return getRoot(self).ordersFactory
  },
  get areThereAnyOrders() {
    if (self.allOrders === null || self.allOrders === 0){
        return false
    }
    else {
        return true
    }
  },
  get searchString() {
    if (self.searchInput === null ){
        return ''
    }
    else {
        return self.searchInput
    }
  },
  get statusQuery() {
    if (self.status === null ){
        return ''
    }
    else {
        return self.status
    }
  },
  get orderCount() {
    if (self.allOrders === null ){
        return 0
    }
    else {
        return self.allOrders
    }
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  }
})