import { api_get_opening_hours, api_update_opening_hours, api_get_holiday_hours, api_create_holiday, api_update_holiday_hours } from "../../../queries/openingHours";
import { toJS } from "mobx";
import { localTime, UTCTime } from "../../../services/time-service";
import { ERRORS } from "../../../constants/strings";
import dayjs from "dayjs";
import { ToasterMsgs } from "../../../constants/toasterMsgs";
import { toastToError, toastToSuccess } from "../../../services/notification-service";

export const holidayHoursStoreActions = self => ({   

async getStoreHolidayHours() {
  try {
      self.set('loading', true)
      const res = await api_get_holiday_hours(self.token, self.merchantId)

      if (res.error) throw res

      const ids = await self.holidayHoursFactory.addUpdateHolidayHours(res.data)
      self.set('allHolidays', [])
      self.set('allHolidays', ids)
      self.set('loading', false)

      self.set('loading', false)
      return res.data

  } catch (err) {
      self.set('loading', false)
      console.log(err)
  }
},
async updateHolidayHours() {
  try {
    self.set('loading', true)

    const data = {
      openHour: self.setOpenHour,
      closeHour: self.setCloseHour,
      closed: self.setClosed,
      title: self.setTitle,
      date: UTCTime(self.setDate)
    }

    const res = await api_update_holiday_hours(data, self.token, self.selectedDate.id)

    if (res.error) throw res

    self.getStoreHolidayHours()

    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successHolidayHoursUpdate)
    return res.data

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async createHoliday() {
  try {
    self.set('loading', true)

    const data = {
      openHour: self.setOpenHour,
      closeHour: self.setCloseHour,
      closed: self.setClosed,
      title: self.setTitle,
      date: UTCTime(self.setDate)
    }

    const res = await api_create_holiday(data, self.token, self.merchantId)

    if (res.error) throw res

    const ids = await self.holidayHoursFactory.addUpdateHolidayHour(res.data.data)
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successHolidayHoursCreated)
    return res.data

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async getAndEditDate(id) {
  self.getStoreHolidayHours()
  self.set('selectedDate', id)
  self.setHolidayHouhrForEdit()
},
setHolidayHouhrForEdit() {
  self.set('setOpenHour', self.selectedDate.openHour)
  self.set('setCloseHour', self.selectedDate.closeHour)
  self.set('setClosed', self.selectedDate.closed)
  self.set('setDate', self.selectedDate.date)
  self.set('setTitle', self.selectedDate.title)
},
resetSelection() {
  self.set('setOpenHour', "0800")
  self.set('setCloseHour', "2200")
  self.set('setClosed', false)
  self.set('setTitle', null)
  self.set('setDate', String(dayjs()))
},
validSetTitle() {
  if (self.title === null || self.title === '' || self.title.length < 1) self.set('setTitleError', ERRORS.ITEM_TITLE_ERROR) 
  else self.set('setTitleError', null)
},
  set(key, value) {
    self[key] = value
  }
})