import { formatCurrency } from "../../../services/currency-service";

export const couponViews = self => ({
    get valueInDollars() {      
      return formatCurrency(self.value/100);
    },
    get formattedValue() {
      return formatCurrency(self.value)
    },
    get active() {
      return self.status === "ACTIVE";
    }
  });
  