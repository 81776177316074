import { types } from "mobx-state-tree"
import * as views from './FeedbackViews'
import * as actions from './FeedbackActions'
import User from "../user/User"
import Merchant from "../merchant/Merchant"
import Order from "../order/Order"

const Feedback = types
  .model("Feedback", {
    id: types.identifierNumber,
    stars: types.maybeNull(types.number),
    text: types.maybeNull(types.string),
    orderId: types.maybeNull(types.number),
    user: types.maybeNull(types.reference(
      User, {}
    )),
    merchant: types.maybeNull(types.reference(
      Merchant, {}
    )),
  })
  .views(views.feedbackViews)
  .actions(actions.feedbackActions)

export default Feedback