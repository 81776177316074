export const OPEN_STATUS = {
  OPEN: "OPEN",
  CLOSED: "CLOSED"
}

export const PRODUCT_ATTRIBUTES_HEADERS = {
  SKU: "SKU",
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  PRICE: "PRICE",
  STOCK: "STOCK",
  // THC: "THC %",
  // CBD: "CBD %",
  // EFFECTS: "EFFECTS",
  // TYPE: "TYPE",
  IMAGE: "HERO",
  // CATEGORY: "CATEGORY",
}

export const PRODUCT_ATTRIBUTES = [
  {
    label: "PACKAGE ID*",
    value: PRODUCT_ATTRIBUTES_HEADERS.SKU
  },
  {
    label: "TITLE",
    value: PRODUCT_ATTRIBUTES_HEADERS.TITLE
  },
  {
    label: "DESCRIPTION",
    value: PRODUCT_ATTRIBUTES_HEADERS.DESCRIPTION
  },
  {
    label: "PRICE",
    value: PRODUCT_ATTRIBUTES_HEADERS.PRICE
  },
  {
    label: "STOCK",
    value: PRODUCT_ATTRIBUTES_HEADERS.STOCK
  },
  // {
  //   label: "THC",
  //   value: PRODUCT_ATTRIBUTES_HEADERS.THC
  // },
  // {
  //   label: "CBD",
  //   value: PRODUCT_ATTRIBUTES_HEADERS.CBD
  // },
  // {
  //   label: "EFFECTS",
  //   value: PRODUCT_ATTRIBUTES_HEADERS.EFFECTS
  // },
  // {
  //   label: "TYPE",
  //   value: PRODUCT_ATTRIBUTES_HEADERS.TYPE
  // },
  {
    label: "IMAGE",
    value: PRODUCT_ATTRIBUTES_HEADERS.IMAGE
  },
  // {
  //   label: "CATEGORY",
  //   value: PRODUCT_ATTRIBUTES_HEADERS.CATEGORY
  // }
]

export const ROWS_PER_PAGE = [10, 30, 50, 100, 150]

export const ORDER_STATUS_OPTIONS = [
  {
    id: 'ALL',
    name: 'All'
  },
  {
    id: 'CANCELED',
    name: 'Canceled'
  },
  {
    id: 'PAID',
    name: 'Not accepted'
  },
  {
    id: 'IN_DELIVERY',
    name: 'In delivery'
  },
  {
    id: 'REFUNDED',
    name: 'Refunded'
  },
  {
    id: 'COMPLETED',
    name: 'Completed'
  }
];