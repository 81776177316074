import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './teamStoreActions'
import * as views from './teamStoreViews'

const TeamStore = types
  .model('TeamStore', {
    teamUsers: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    allTeamUsers: types.maybeNull(types.number),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(views.teamStoreViews)
  .actions(actions.teamStoreActions)

export default TeamStore