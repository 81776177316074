import { types } from "mobx-state-tree"
import * as views from './LocationViews'
import * as actions from './LocationActions'

const Location = types
  .model("Location", {
    id: types.identifierNumber,
    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    buildingNumber: types.maybeNull(types.string),
    streetName: types.maybeNull(types.string),
    streetNumber: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    districtName: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    post: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    floor: types.maybeNull(types.string),
    instructions: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
  })
  .views(views.locationViews)
  .actions(actions.locationActions)

export default Location