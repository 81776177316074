import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

export const hourFromTime = (time) => {
  return new Date(time).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  })
}


export const localTime = (time) => {
  dayjs.extend(utc)
  return dayjs.utc(time).local().format()
}

export const UTCTime = (time) => {
  dayjs.extend(utc)
  return dayjs.utc(time).format()
}

export const localDateAndTime = (time) => {
  dayjs.extend(utc)
  return dayjs.utc(time).local().format('DD MMM YYYY | h:mm A')
}

export const convertToTime = (hourString) => {
  const hours = parseInt(hourString.substring(0, 2), 10);
  const minutes = parseInt(hourString.substring(2, 4), 10);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);      
  date.setMilliseconds(0);  
  return date;
}

export const convertToSimpleTimeString = (hourString) => {
  const timeString = (dayjs(hourString).format('HHmm'))
  return timeString;
}

export const convertToSimpleTimeStringForDispaly = (hourString) => {
  const timeString = (dayjs(hourString).format('hh:mm a'))
  return timeString;
}