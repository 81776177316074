import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { toJS } from 'mobx'

const ProtectedRouteMerchant = (props) => {

  const { authStore, accountStore } = props.store
  
  if (!authStore.checkIfUserIsStillLegit()) return <Navigate to="/login" replace={true} />
  if (!authStore.isAuthenticated) return <Navigate to="/login" replace={true} />
  if (!authStore.isMerchantFullyOnboarded) return <Navigate to="/complete-registration" replace={true} />

  if (!accountStore.isUserImpersonatingOrMerchant) return <Navigate to="/support/merchants" replace={true} />

  return props.children
}

export default inject('store')(observer(ProtectedRouteMerchant))


