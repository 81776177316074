import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_users = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/users`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_users_paginate = async (token, page, row, query="") => {
  try {

    let queryWhere = ``
    if (query !== "") {
      queryWhere = `&query=${query}`
    }

    const res = await axios.get(`${API}/cms/users/all?page=${page}&items=${row}${queryWhere}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_team_users = async (token, merchantId) => {
  try {
    const res = await axios.get(`${API}/cms/employees/${merchantId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_user_by_id = async (username, token) => {
  try {
    const res = await axios.get(`${API}/cms/user/${username}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}
//&role=MERCHANT_MANAGER

export const api_get_users_by_querry = async (token, searchQuerry) => {
  try {
    const res = await axios.get(`${API}/cms/users/all?query=${searchQuerry}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_delet_user = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/users/${id}/archive`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_team_user = async (data, token, userId) => {
  try {
    const res = await axios.put(`${API}/cms/users/${userId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

