import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"
export const effectsStoreViews = self => ({
  get effectsFactory() {
    return getRoot(self).effectsFactory
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
})