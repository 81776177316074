import { types } from 'mobx-state-tree'
import User from '../../models/user/User'
import * as views from './accountStoreViews'
import * as actions from './accountStoreActions'

const AccountStore = types
  .model('accountStore', {
    user: types.maybeNull(
      types.reference(User)
    ),
  })
  .views(views.accountStoreViews)
  .actions(actions.accountStoreActions)

export default AccountStore
