import { hourFromTime } from "../../../services/time-service"
import { convertToSimpleTimeStringForDispaly, convertToTime } from "../../../services/time-service"

export const holidayHoursViews = self => ({
  get openHourStr() {
    return convertToSimpleTimeStringForDispaly(convertToTime(self.openHour))
  },
  get closeHourStr() {
    return convertToSimpleTimeStringForDispaly(convertToTime(self.closeHour))
  }
})