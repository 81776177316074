import { hourFromTime } from "../../../services/time-service"
import { convertToSimpleTimeStringForDispaly, convertToTime } from "../../../services/time-service"

export const openingHoursViews = self => ({
  get day() {
    switch(self.dayOfTheWeek) {
      case 0:
        return 'Monday'
      case 1: 
        return 'Tuseday'
      case 2: 
        return 'Wednesday'
      case 3:
        return 'Thursday'
      case 4:
        return 'Friday'
      case 5:
        return 'Saturday'
      case 6:
        return 'Sunday'
    }
  },
  get openHourStr() {
    return convertToSimpleTimeStringForDispaly(convertToTime(self.openHour))
  },
  get closeHourStr() {
    return convertToSimpleTimeStringForDispaly(convertToTime(self.closeHour))
  }
})