import { types } from 'mobx-state-tree'
import Coupon from '../../models/coupon/coupon'
import * as views from './couponsStoreViews'
import * as actions from './couponsStoreActions'

const CouponsStore = types
  .model('couponsStore', {
    coupons: types.maybeNull(types.optional(
      types.array(types.reference(Coupon)), []
    )),
    updating: false,
  })
  .views(views.couponsStoreViews)
  .actions(actions.couponsStoreActions)

export default CouponsStore
