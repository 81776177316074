import axios from 'axios'
import { _throw } from "../../../utils/error-service"
import { API } from "../../../constants/system"
import { toJS } from 'mobx'

export const assetActions = self => ({
  async uploadAsset(file, fileType, token) {
    try {
      const res = await self.uploadAction(file, fileType, token)
      if (res.error) throw res
      
      return res
    } catch (err) {
      self.set('progress', 0)
      return {
        error: true,
        ...err
      }
    }
  },
  async upload(token, file, preview, type, merchantId) {
    try {
      self.set('loading', true)
      self.set('localUrl', preview) 
      
      const data = new FormData()

      data.append('fileType', type)
      data.append('file', file)
      data.append('uniqueId', merchantId)
      const res = await self.uploadAction(data, token, type)

      self.set('originUrl', res.originUrl)
      self.set('id', res.id)
      self.set('loading', false)

      return res

    } catch (err) {
      console.log(err)
      self.set('localUrl', null) 
      self.set('loading', false)
      return _throw(err)
    }
  },
  async uploadAction(data, authToken, type) {
    const config = {
      onUploadProgress: progressEvent => {
        const progress = progressEvent.loaded/progressEvent.total
        if (Math.ceil(progress*100) > 10)
          self.set('progress', Math.ceil(progress*100))
        },
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authToken
      }
    }
    const res = await axios.post(`${API}/assets?fileType=${type}`, data, config)  
    return res.data
  },
  update(asset) {
    if (!asset) return
    self.set('id', asset.id)
    self.set('title', asset.title)
    self.set('fileSize', asset.fileSize)
    self.set('fileType', asset.fileType)
    self.set('originUrl', asset.originUrl)
    self.set('mimetype', asset.mimetype)
    self.set('localUrl', asset.localUrl)
    self.set('uploadedType', asset.uploadedType)
  },
  set(key, value) {
    self[key] = value
  }
})