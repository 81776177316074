import { types } from "mobx-state-tree"
import * as actions from './CategoryActions'
import Asset from "../asset/Asset"

const Category = types
  .model('Category', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    icon: types.maybeNull(Asset),
    itemCount: types.maybeNull(types.number),
  })
  .views(self => ({

  }))
  .actions(actions.categoryActions)

export default Category