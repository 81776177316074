import find from 'lodash/find'
import { api_get_product_by_id } from '../../../queries/products'
import { _add_update_objects } from '../../../services/factories-service'

export const productsFactoryActions = self => ({
  addUpdateProducts(products) {
    return _add_update_objects(products, self.products, self.parseProduct)
  },
  addUpdateProduct(p) {
    const ids = self.addUpdateProducts([p])
    return ids[0]
  },
  parseProduct(p) {
    return ({
      ...p,
      category: p.category && self.categoriesFactory.addUpdateCategory(p.category),
      effects: Array.isArray(p.effects) && p.effects.length ? p.effects : null,
    })
  },
  async getProduct(mId) {
    try {
      const id = parseInt(mId)

      let product = find(self.products, p => p.id === id)
      if (product) return product

      const res = await self.fetch(id)
      if (res.error) throw res

      return find(self.products, p => p.id === id)
    } catch (err) {
      return {
        ...err,
        error: true
      }
    }
  },
  async fetch(id) {
    try {
      const res = await api_get_product_by_id(id)
      if (res.error) throw res

      self.addUpdateProduct(res)

      return res
    } catch (err) {
      return err
    }
  },
})