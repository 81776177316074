import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"

export const singleCategoryViews = self => ({
  get fullPrice() {
    return 'US$' + self.quantity * self.product?.price
  },
  get valid() {
    self.validTitle()
    self.validDescription()
    return ( 
      self.titleError || 
      self.descriptionError 
      ) === null
  },
  get isTitleError() {
    if (self.titleError) {
        return true
    } else {
        return false
    }
  },
  get isDescriptionError() {
    if (self.descriptionError) {
        return true
    } else {
        return false
    }
  },
  get isFormFilledOut() {
    if (self.title !== null && self.description !== null && self.asset !== null && self.icon !== null ) {
        return true
    } else {
        return false
    }
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantStore() {
    return getRoot(self).merchantStore
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get effectsFactory() {
    return getRoot(self).effectsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore?.id
  },
  get userId() {
    return getRoot(self).authStore.managingStore?.id
  },
})