import { types } from "mobx-state-tree"
import * as actions from './holidayHoursFactoryActions'
import * as views from './holidayHoursFactoryViews'
import HolidayHours from "../../models/holidayHours/HolidayHours"

const HolidayHoursFactory = types
  .model('HolidayHoursFactory', {
    holidayHours: types.optional(
      types.array(HolidayHours), []
    )
  })
  .views(views.holidayHoursFactoryViews)
  .actions(actions.holidayHoursFactoryActions)

export default HolidayHoursFactory