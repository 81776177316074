import find from 'lodash/find'
import { api_get_category_by_id } from '../../../queries/categories'
import { _add_update_objects } from '../../../services/factories-service'

export const openingHoursFactoryActions = self => ({
  addUpdateOpeningHours(openingHours) {
    return _add_update_objects(openingHours, self.openingHours, self.parseOpeningHour)
  },
  addUpdateOpeningHour(p) {
    const ids = self.addUpdateOpeningHours([p])
    return ids[0]
  },
  parseOpeningHour(p) {
    return ({
      ...p,
    })
  },
  async getCategory(mId) {
    try {
      const id = parseInt(mId)

      let category = find(self.categories, p => p.id === id)
      if (category) return category

      const res = await self.fetch(id)
      if (res.error) throw res

      return find(self.categories, p => p.id === id)
    } catch (err) {
      return {
        ...err,
        error: true
      }
    }
  },
  async fetch(id) {
    try {
      const res = await api_get_category_by_id(id)
      if (res.error) throw res

      self.addUpdateCategory(res)

      return res
    } catch (err) {
      return err
    }
  },
})