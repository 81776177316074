import { api_accounting_export_orders, api_accounting_get_orders, api_update_all_orders_settled } from "../../../queries/accounting"
import { api_get_feedbacks } from "../../../queries/feedbacks"
import { toJS } from "mobx"
import { api_update_order_amount_settled } from "../../../queries/accounting"

export const accountingStoreActions = self => ({  

  async getOrders(reset, query=self.query) {
    try {

      if (reset) {
        self.set('page', 1)
      }

      self.set('loading', true)
      const res = await api_accounting_get_orders(
        self.token, 
        self.merchantId, 
        query, 
        [self.fromDate.toISOString(), self.toDate.toISOString()],
        self.page, 
        self.limit
      )

      if (res.error) throw res
      const ids = await self.ordersFactory.addUpdateOrders(res.data.data.orders.rows)

      self.set('ordersCount', res.data.data.orders.count)
      self.set('orders', ids)
      self.set('unsettledSum', res.data.data.unsettledSum.toString())
      self.set('loading', false)


      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },
  async csvExport() {
    try {
      self.set('loading', true)
      const res = await api_accounting_export_orders(
        self.token, 
        self.merchantId, 
        self.query, 
        [self.fromDate.toISOString(), self.toDate.toISOString()]
      )

      const url = res.data?.data?.url

      if (!url) throw res

      self.set('loading', false)

      window.open(url, '_blank')


      return res


    } catch (err) {
      self.set('loading', false)

      console.log(err)
    }
  },
  async updateOrderMerchantSettledStatus(order) {
      try {
        self.set('loading', true)

        const data = {
          order: {
              settled: order.settled,
          }
        }

        const res = await api_update_order_amount_settled(data, self.token, self.merchantId, order.id)
        //const ids = await self.ordersFactory.addUpdateOrder(res.data.data)
        self.getOrders()

        if (res.error) throw res
        //toastToSuccess(ToasterMsgs.successStorefrontUpdated)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        order.set('settled', !order.settled)
      // toastToError(ToasterMsgs.genericError)
        console.log(err)
      }
  },
  async markAllAsSettled() {
    try {
      
      const res = await api_update_all_orders_settled(self.token, self.merchantId)

      if (res.error) throw res

      self.getOrders()
      return res.data

    } catch (err) {
      return err
    }
  },
  set(key, value) {
    self[key] = value
  }

  })