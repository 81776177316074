import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"

export const singleNotificationViews = self => ({
  get fullPrice() {
    return 'US$' + self.quantity * self.product?.price
  },
  get valid() {
    self.validTitle();
    self.validText();
    return (
      self.titleError ||
      self.textError
    ) === null;
  },
  get isTitleError() {
    return self.titleError ? true : false;
  },
  get isTextError() {
    return self.couponError ? true : false;
  },
  get isFormFilledOut() {

    if (self.type === "INFO") {
      return (
        self.title !== null && self.title !== "" &&
        self.text !== null && self.text !== ""
      );
    }

    if (self.type === "PROMOTION") {
      return (
        self.title !== null && self.title !== "" &&
        self.text !== null && self.text !== "" &&
        self.selectedCoupon !== null
      );
    }

    if (self.type === "DISPENSARY_OPEN") {
      return self.title !== null && self.title !== "" &&
      self.text !== null && self.text !== "" &&
      self.selectedMerchant !== null
    }
  },
  get textNotification() {
    if (self.text === null) return '';
    return self.text
  },
  get titleNotification() {
    if (self.title === null) return '';
    return self.title
  },
  get couponTitleNotification() {
    if (self.selectedCoupon === null && self.selectedCoupon?.title === null) return '';
    return self.selectedCoupon?.title
  },
  get couponTextNotification() {
    if (self.selectedCoupon === null && self.selectedCoupon?.text === null) return '';
    return self.selectedCoupon?.text
  },
  get couponValueNotification() {
    if (self.selectedCoupon === null && self.selectedCoupon?.value === null) return '';
    return self.selectedCoupon?.valueInDollars
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantStore() {
    return getRoot(self).merchantStore
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get effectsFactory() {
    return getRoot(self).effectsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore?.id
  },
  get userId() {
    return getRoot(self).authStore.managingStore?.id
  },
})