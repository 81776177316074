import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './merchantDriversStoreActions'
import * as views from './merchantDriversStoreViews'

const MerchantDriversStore = types
  .model('MerchantDriversStore', {
    drivers: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    allDrivers: types.maybeNull(types.number),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(views.merchantDriversStoreViews)
  .actions(actions.merchantDriversStoreActions)

export default MerchantDriversStore