import { types } from "mobx-state-tree"
import * as actions from './OpeningHoursActions'
import * as views from './OpeningHoursViews'

const OpeningHours = types
  .model("OpeningHours", {
    id: types.identifierNumber,
    openHour: types.maybeNull(types.string),
    closeHour: types.maybeNull(types.string),
    dayOfTheWeek: types.maybeNull(types.number),
    closed: types.maybeNull(types.boolean),
  })
  .views(views.openingHoursViews)
  .actions(actions.openingHoursActions)

export default OpeningHours