import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import Asset from "../../models/asset/Asset"
import { api_post_product, api_get_product_by_id, api_update_product, } from "../../../queries/products"
import { api_get_merchant_storefront, api_update_merchant_storefront } from "../../../queries/merchants"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { parse } from "date-fns"

export const storefrontActions = self => ({
  resetProduct(id) {
    self.set('product', id)
  },
  validTitle() {
    if (self.title === null || self.title === '' || self.title.length < 1) self.set('titleError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('titleError', null)
  },
  validDescription() {
    if (self.description === null || self.description.length < 1) self.set('descriptionError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('titleError', null)
  },
  validLegalName() {
    if (self.legalName === null || self.legalName.length < 1 ) self.set('legalNameError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('legalNameError', null)
  },
  validLicenceNumber() {
    if (self.licenceNumber === null || self.licenceNumber.length < 1 ) self.set('licenceNumberError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('licenceNumberError', null)
  },
  validTaxId() {
    if (self.taxId === null || self.taxId.length < 1 ) self.set('taxIdError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('taxIdError', null)
  },
  validEmail() {
    if (self.email === null || self.email.length < 1  ) self.set('emailError', ERRORS.ITEM_TAX_RATE_ERROR)
    else self.set('emailError', null)
  },
  validPhone() {
    if (self.phone === null || self.phone.lenght < 1 ) self.set('phoneError', ERRORS.ITEM_THC_ERROR)
    else self.set('phoneError', null)
  },
  validDeliveryCommission() {
    if (self.deliveryCommision === null || self.deliveryCommision.length < 1 ) self.set('deliveryCommisionError', ERRORS.ITEM_CBD_ERROR)
    else self.set('deliveryCommissionError', null)
  },
  validPickupCommision() {
    if (self.pickupCommision === null || self.pickupCommision.length < 1 ) self.set('pickupCommisionError', ERRORS.ITEM_CBD_ERROR)
    else self.set('pickupCommisionError', null)
  },
  clearInputs() {
    self.set('titleError', null)
    self.set('descriptionError', null)
    self.set('legalNameError', null)
    self.set('emailError', null)
    self.set('phoneError', null)
    self.set('deliveryCommissionError', null)
    self.set('pickupCommisionError', null)
  },
  setProductForEdit() {
    self.set('title', self.selectedProduct.title)
    self.set('description', self.selectedProduct.description)
    self.set('basePrice', self.selectedProduct.basePrice)
    self.set('tax', self.selectedProduct.tax)
    self.set('stock', self.selectedProduct.stock)
    self.set('thc', self.selectedProduct.thc)
    self.set('cbd', self.selectedProduct.cbd)
    self.set('category', self.categoryIdView)
    self.createAndUpdateAsset(self.selectedProduct.assets)
  },
  createAndUpdateAsset(newAsset) {
    if (newAsset !== null || newAsset.length === 0) {
    const asset = Asset.create()
    self.set('asset', asset)
    self.asset.update(newAsset)
    } else {
      return false
    }
  },
  async updateStorefront() {
    try {
      self.set('loading', true)

      const dataMerchant = {
        title: self.title,
        description: self.description,
        website: self.website,
        heroPictureId: self.asset.id,
        status: self.status
      }

      const res = await api_update_merchant_storefront(dataMerchant, self.token, self.merchantStorefront.id)

      if (res.error) throw res
      toastToSuccess(ToasterMsgs.successStorefrontUpdated)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
  
},
  async uploadAsset(file, preview) {
    self.set('loadingAsset', true)
    try {
      const asset = Asset.create()
      self.set('asset', asset)

      const res = await asset.upload(
        self.token,
        file,
        preview,
        'MERCHANT_HERO',
        self.merchantStorefront.id
      )

      if (res.error) throw res

      self.set('loadingAsset', false)
      return res.data
    } catch (err) {
      self.set('loadingAsset', false)
      console.log(err)
    }
  },
  async getAndEditStorefront(merchantId) {
    try {
      self.set('loading', true)
      const res = await api_get_merchant_storefront(self.token, merchantId)

      if (res.error) throw res

      const ids = await self.merchantsFactory.addUpdateMerchant(res)

      self.clearInputs()
      self.set('merchantStorefront', ids)
      self.setStorefrontForEdit(res)

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
    }
  },
  set(key, value) {
    self[key] = value
  },
  setStorefrontForEdit(storefront) {
    self.set('title', storefront.title)
    self.set('description', storefront.description)
    self.set('legalName', storefront.legalName)
    self.set('status', storefront.status)
    self.set('email', storefront.email)
    self.set('phone', storefront.phone)
    self.set('website', storefront.website)
    self.set('deliveryCommission', storefront.deliveryCommission.toString())
    self.set('pickupCommission', storefront.pickupCommission)
    self.set('licenceNumber', storefront.licenceNumber)
    self.set('taxId', storefront.taxId)
    self.createAndUpdateAsset(storefront.hero)
  },
})