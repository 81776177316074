import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_drivers = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/drivers`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_merchant_drivers = async (token, merchantId) => {
  try {
    const res = await axios.get(`${API}/cms/drivers/${merchantId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_driver_tasks = async (token, driverId) => {
  try {
    const res = await axios.get(`${API}/cms/driver-tasks/${driverId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delete_driver = async (token, driverId) => {
  try {
    const res = await axios.delete(`${API}/cms/driver/${driverId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_drivers_paginate = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/cms/drivers?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_driver = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/driver`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_active_drivers = async (token, merchantId) => {
  try {
    const res = await axios.get(`${API}/cms/active-drivers/${merchantId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_assign_driver_to_order = async (token, orderId, driverId) => {
  try {
    const res = await axios.post(`${API}/cms/assign-driver-to-order/${driverId}/${orderId}`, {
    }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}