import differenceBy from 'lodash/differenceBy'
import intersectionBy from 'lodash/intersectionBy'
import filter from 'lodash/filter'
import map from 'lodash/map'

export const _add_update_objects = (objects, current_objects, parse_single_object) => {
  const add = differenceBy(objects, current_objects, 'id')
  const update = intersectionBy(current_objects, objects, 'id')
  map(update, ob => ob.update(
    parse_single_object(filter(objects, o => o.id === ob.id)[0])
  ))
  const parsed = map(add, d => {
    return (parse_single_object(d))
  })
  current_objects.push(...parsed)
  return map(objects, o => o.id)
};
