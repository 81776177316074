import { toJS } from 'mobx'
import { _add_update_objects } from '../../../services/factories-service'

export const paymentMethodsFactoryActions = self => ({
  addUpdatePaymentMethods(paymentMethods) {
    return _add_update_objects(paymentMethods, self.paymentMethods, self.parsePaymentMethods)
  },
  addUpdatePaymentMethod(p) {
    const ids = self.addUpdatePaymentMethods([p])
    return ids[0]
  },
  parsePaymentMethods(f) {
    return ({
      ...f,
      //user: f.user?.id ? self.usersFactory.addUpdateUser(f.user) : null,
      //merchant: f.merchant?.id ? self.merchantsFactory.addUpdateMerchant(f.merchant) : null,
    })
  },
})