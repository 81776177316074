import { types } from "mobx-state-tree"
import * as actions from './DeliveryTaskActions'
import * as views from './DeliveryTaskViews'

const DeliveryTask = types
  .model("DeliveryTask", {
    id: types.identifierNumber,
    driverId: types.maybeNull(types.number),
    onFleetTaskId: types.maybeNull(types.string),
    pickup: types.maybeNull(types.boolean),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .views(views.deliveryTaskViews)
  .actions(actions.deliveryTaskActions)

export default DeliveryTask