import { types } from 'mobx-state-tree'
import * as views from './singleOrderStoreViews'
import * as actions from './singleOrderStoreActions'
import Order from '../../models/order/Order'
import User from '../../models/user/User'

const SingleOrderStore = types
  .model('singleOrderStore', {
    order: types.maybeNull(types.reference(Order)),
    driver: types.maybeNull(types.reference(User)),
    loading: false,
    loadingDrivers: false
  })
  .views(views.singleOrderStoreViews)
  .actions(actions.singleOrderStoreActions)

export default SingleOrderStore
