import { types } from 'mobx-state-tree'
import Category from '../../models/category/Category'
import * as views from './effectsStoreViews'
import * as actions from './effectsStoreActions'
import Effect from '../../models/effect/Effect'

const EffectsStore = types
  .model('EffectsStore', {
    effects: types.maybeNull(types.optional(
      types.array(types.reference(Effect)), []
    )),
    allCategories: types.maybeNull(types.number),
    updating: false,
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
  })
  .views(views.effectsStoreViews)
  .actions(actions.effectsStoreActions)

export default EffectsStore
