import { toJS } from "mobx"
import { getRoot } from "mobx-state-tree"

export const addUpdateProductStoreViews = self => ({
  get fullPrice() {
    return 'US$' + self.quantity * self.product?.price
  },
  get valid() {
    self.validTitle()
    self.validDescription()
    self.validBasePrice()
    self.validTax()
    // self.validThc()
    self.validAsset()
    // self.validCbd()
    self.validStock()
    return ( 
      self.titleError || 
      self.descriptionError || 
      self.basePriceError ||
      self.basePriceError ||
      self.taxError ||
      // self.thcError ||
      self.assetError ||
      // self.cbdError ||
      self.stockError 
      ) === null
  },
  get isTitleError() {
    if (self.titleError) {
        return true
    } else {
        return false
    }
  },
  get isSkuError() {
    if (self.skuError) {
        return true
    } else {
        return false
    }
  },
  get isDescriptionError() {
    if (self.descriptionError) {
        return true
    } else {
        return false
    }
  },
  get isAssetError() {
    if (self.assetError) {
        return true
    } else {
        return false
    }
  },
  get isBasePriceError() {
    if (self.basePriceError) {
        return true
    } else {
        return false
    }
  },
  get isTaxError() {
    if (self.taxError) {
        return true
    } else {
        return false
    }
  },
  get isThcError() {
    if (self.thcError) {
        return true
    } else {
        return false
    }
  },
  get isCbdError() {
    if (self.cbdError) {
        return true
    } else {
        return false
    }
  },
  get isStockError() {
    if (self.stockError) {
        return true
    } else {
        return false
    }
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantStore() {
    return getRoot(self).merchantStore
  },
  get authStore() {
    return getRoot(self).authStore
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get categoriesStore() {
    return getRoot(self).categoriesStore
  },
  get effectsFactory() {
    return getRoot(self).effectsFactory
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get categoryIdView() {
    if (self.selectedProduct.category !== null) {
        return self.selectedProduct.category.id
    } else {
        return null
    }
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get listOfSelecteEffects() {
    if (self.effects !== null || self.effects.length > 0) {
      const titles = self.effects.map(obj => obj.title).join(', ');
      return titles
    } else {
      return []
    }
  },
  get selectedEffects() {
    if (self.effects !== null || self.effects.length > 0) {
      return self.effects?.map(obj => obj.id)
    } else {
      return []
    }
  },
    get firstCategoryInArray() {
    if (self.categoriesStore.categories !== null) {
      return self.categoriesStore?.categories[0]?.id
    } else {
      return null
    }
  },
  get selectedEffectsIds() {
    if (self.effects !== null) {
      return self.effects.map(effect => effect.id);
    } else {
      return [];
    }
  },
  get statusColor() {
    switch (self.status) {
      case 'ACTIVE':
        return 'success'
      case 'DRAFT':
        return 'warning'
      default:
        return null
    }
  }

})