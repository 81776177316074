import { types } from "mobx-state-tree"
import Product from "../../models/product/Product"
import Category from "../../models/category/Category"
import * as views from './supportMerchantOrdersViews'
import * as actions from './supportMerchantOrdersActions'
import Order from "../../models/order/Order"

const SupportMerchantOrders = types
  .model('SupportMerchantOrdersStore', {
    order: types.maybeNull(types.reference(Order)),
    orders: types.maybeNull(types.optional(
      types.array(types.reference(Order)), []
    )),
    allOrders: types.maybeNull(types.number),
    selectedOrder: types.maybeNull(types.reference(Order)),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
    searchInput: types.maybeNull(types.string, ''),
    status: types.maybeNull(types.string, ''),
  })
  .views(views.supportMerchantOrdersViews)
  .actions(actions.supportMerchantOrdersActions)

export default SupportMerchantOrders