import { types } from "mobx-state-tree"
import Asset from "../asset/Asset"
import Location from "../location/Location"
import Merchant from "../merchant/Merchant"
import * as actions from './UserActions'
import * as views from './UserViews'
import PaymentMethod from "../paymentMethod/PaymentMethod"

const User = types
  .model('User', {
    id: types.identifierNumber,
    hashId: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    locations: types.maybeNull(
      types.array(types.reference(Location))),
    // paymentMethods: types.maybeNull(
    //   types.array(types.reference(PaymentMethod))),
    paymentMethods: types.maybeNull(
      types.array(types.reference(PaymentMethod)), []
    ),
    status: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    avatar: types.maybeNull(
      types.optional(Asset, {})
    ),
    managingStore: types.maybeNull(types.reference(Merchant)),
    merchant: types.maybeNull(types.reference(Merchant)),
    countryPrefix: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
  })
  .views(views.UserViews)
  .actions(actions.UserActions)

export default User