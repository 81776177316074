import { types } from "mobx-state-tree"
import User from '../../models/user/User'
import * as views from './usersStoreViews'
import * as actions from './usersStoreActions'


const UsersStore = types
  .model('UsersStore', {
    users: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    
    allUsers: types.maybeNull(types.number),
    selectedUser: types.maybeNull(types.reference(User)),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(views.usersStoreViews)
  .actions(actions.usersStoreActions)

export default UsersStore