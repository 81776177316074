import { types } from 'mobx-state-tree'
import AuthStore from '../stores/auth/authStore'
import AccountStore from '../stores/account/accountStore'
import UsersFactory from '../factories/users/usersFactory'
import UsersStore from '../stores/user/usersStore'
import MerchantsFactory from '../factories/merchants/merchantsFactory'
import AddUpdateProductStore from '../stores/addUpdateProduct/addUpdateProductStore'
import ProductsFactory from '../factories/products/productsFactory'
import CategoriesFactory from '../factories/categories/categoriesFactory'
import OpeningHoursFactory from '../factories/openingHours/openingHoursFactory'
import CategoriesStore from '../stores/categories/categoriesStore'
import OpeningHoursStore from '../stores/openingHours/openingHoursStore'
import MerchantProductsStore from '../stores/merchantProducts/merchantProductsStore'
import HolidayHoursFactory from '../factories/holidayHours/holidayHoursFactory'
import HolidayHoursStore from '../stores/holidayHours/holidayHoursStore'
import OrdersFactory from '../factories/orders/ordersFactory'
import MerchantOrdersStore from '../stores/merchantOrders/merchantOrdersStore'
import SingleOrderStore from '../stores/singleOrder/singleOrderStore'
import StorefrontStore from '../stores/storefront/storefrontStore'
import TeamStore from '../stores/team/teamStore'
import TeamMemberStore from '../stores/teamMember/teamMemberStore'
import FeedbacksFactory from '../factories/feedbacks/feedbacksFactory'
import FeedbacksStore from '../stores/feedbacks/feedbacksStore'
import MerchantsStore from '../stores/merchants/merchantsStore'
import SupportMerchantOrdersStore from '../stores/supportMerchantOrders/supportMerchantOrders'
import LocationsFactory from '../factories/locations/locationsFactory'
import DriversStore from '../stores/drivers/driversStore'
import EditDriverStore from '../stores/editDriver/editDriverStore'
import DeliveryTasksFactory from '../factories/deliveryTasks/deliveryTasksFactory'
import NewOrderStore from '../stores/newOrder/newOrderStore'
import SystemSettingsStore from '../stores/systemSettings/systemSettingsStore'
import MerchantDriversStore from '../stores/merchantDrivers/merchantDriversStore'
import PaymentMethodFactory from '../factories/paymentMethods/paymentMethodsFactory'
import EffectsFactory from '../factories/effects/effectsFactory'
import EffectsStore from '../stores/effects/effectsStore'
import SingleEffectStore from '../stores/singleEffect/singleEffectStore'
import SingleCategoryStore from '../stores/singleCategory/singleCategoryStore'
import SettingsStore from '../stores/settings/settingsStore'
import CouponsStore from '../stores/coupons/couponsStore'
import CouponsFactory from '../factories/coupons/couponsFactory'
import SingleCouponStore from '../stores/singleCoupon/singleCouponStore'
import SingleNotificationStore from '../stores/singleNotification/singleNotificationStore'

import { toJS } from 'mobx'
import StatisticsStore from '../stores/statistics/statisticsStore'
import ImportMenuStore from '../stores/importMenu/importMenuStore'
import AccountingStore from '../stores/accounting/accountingStore'

const Store = types
  .model({
    authStore: types.optional(AuthStore, {}),
    accountStore: types.optional(AccountStore, {}),
    usersFactory: types.optional(UsersFactory, {}),
    merchantsFactory: types.optional(MerchantsFactory, {}),
    usersStore: types.optional(UsersStore, {}),
    addUpdateProductStore: types.optional(AddUpdateProductStore, {}),
    productsFactory: types.optional(ProductsFactory, {}),
    categoriesFactory: types.optional(CategoriesFactory, {}),
    categoriesStore: types.optional(CategoriesStore, {}),
    merchantProductsStore: types.optional(MerchantProductsStore, {}),
    openingHoursStore: types.optional(OpeningHoursStore, {}),
    openingHoursFactory: types.optional(OpeningHoursFactory, {}),
    holidayHoursFactory: types.optional(HolidayHoursFactory, {}),
    holidayHoursStore: types.optional(HolidayHoursStore, {}),
    ordersFactory: types.optional(OrdersFactory, {}),
    merchantOrdersStore: types.optional(MerchantOrdersStore, {}),
    singleOrderStore: types.optional(SingleOrderStore, {}),
    storefrontStore: types.optional(StorefrontStore, {}),
    teamStore: types.optional(TeamStore, {}),
    teamMemberStore: types.optional(TeamMemberStore, {}),
    feedbacksStore: types.optional(FeedbacksStore, {}),
    feedbacksFactory: types.optional(FeedbacksFactory, {}),
    merchantsStore: types.optional(MerchantsStore, {}),
    supportMerchantOrdersStore: types.optional(SupportMerchantOrdersStore, {}),
    locationsFactory: types.optional(LocationsFactory, {}),
    driversStore: types.optional(DriversStore, {}),
    editDriverStore: types.optional(EditDriverStore, {}),
    deliveryTasksFactory: types.optional(DeliveryTasksFactory, {}),
    newOrderStore: types.optional(NewOrderStore, {}),
    systemSettingsStore: types.optional(SystemSettingsStore, {}),
    merchantDriversStore: types.optional(MerchantDriversStore, {}),
    paymentMethodsFactory: types.optional(PaymentMethodFactory, {}),
    effectsFactory: types.optional(EffectsFactory, {}),
    effectsStore: types.optional(EffectsStore, {}),
    singleEffectStore: types.optional(SingleEffectStore, {}),
    singleCategoryStore: types.optional(SingleCategoryStore, {}),
    settingsStore: types.optional(SettingsStore, {}),
    statisticsStore: types.optional(StatisticsStore, {}),
    couponsStore: types.optional(CouponsStore, {}),
    couponsFactory: types.optional(CouponsFactory, {}),
    singleCouponStore: types.optional(SingleCouponStore, {}),
    singleNotificationStore: types.optional(SingleNotificationStore, {}),
    importMenuStore: types.optional(ImportMenuStore, {}),
    accountingStore: types.optional(AccountingStore, {}),
    hydrated: false
  })
  .views(self => ({

  }))
  .actions(self => ({
    reset() {
      self.set('authStore', {})
      self.set('accountStore', {})
      self.set('merchantFactory', {})
      self.set('merchantsFactory', {})
      self.set('merchantsStore', {})
      self.set('usersFactory', {})
      self.set('usersStore', {})
      self.set('addUpdateProductStore', {})
      self.set('productsFactory', {})
      self.set('categoriesFactory', {})
      self.set('categoriesStore', {})
      self.set('openingHoursFactory', {})
      self.set('holidayHoursFactory', {})
      self.set('ordersFactory', {})
      self.set('feedbacksFactory', {})
      self.set('supportMerchantOrdersStore', {})
      self.set('locationsFactory', {})
      self.set('driversStore', {})
      self.set('editDriverStore', {})
      self.set('deliveryTasksFactory', {})
      self.set('newOrderStore', {})
      self.set('systemSettingsStore', {})
      self.set('couponsStore', {})
      self.set('accountingStore', {})
      self.resetMerchantData()
    },
    initializeBudz() {
      self.resetMerchantData()
      self.usersStore.getUser(self.accountStore.user.hashId)
      self.categoriesStore.getCategories()
      self.effectsStore.getEffects()
    },
    resetMerchantData() {
      self.set('merchantDriversStore', {})
      self.set('openingHoursStore', {})
      self.set('holidayHoursStore', {})
      self.set('merchantProductsStore', {})
      self.set('merchantOrdersStore', {})
      self.set('singleOrderStore', {})
      self.set('storefrontStore', {})
      self.set('teamStore', {})
      self.set('teamMemberStore', {})
      self.set('feedbacksStore', {})
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default Store
