import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './teamMemberStoreActions'
import * as views from './teamMemberStoreViews'

const TeamMemberStore = types
  .model('TeamMemberStore', {
    selectedTeamMember: types.maybeNull(types.reference(User)),
    firstName: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    role: types.maybeNull(types.string),

    loading: false,
  })
  .views(views.teamMemberStoreViews)
  .actions(actions.teamMemberStoreActions)

export default TeamMemberStore