import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"
export const editDriverStoreViews = self => ({   
    get token() {
        return getRoot(self).authStore.token
      },
      get usersFactory() {
        return getRoot(self).usersFactory
      },
      get authStore() {
        return getRoot(self).authStore
      },
      get driversStore() {
        return getRoot(self).driversStore
      },
      get deliveryTasksFactory() {
        return getRoot(self).deliveryTasksFactory
      },
      get ordersFactory() {
        return getRoot(self).ordersFactory
      },
      get merchantId() {
        if (self.authStore.managingStore !== null) {
          return null
        } else {
          return getRoot(self).authStore.managingStore.id
        }
      },
      get isThereOrder() {
        if (self.selectedDriverOrders === null || !Array.isArray(self.selectedDriverOrders) || !self.selectedDriverOrders.length){
            return false
        }
        else {
            return true
        }
      },
      get valid() {
        self.validFirstName()
        self.validLastName()
        self.validEmail()
        return ( 
          self.firstNameError || 
          self.lastNameError || 
          self.emailError 
          ) === null
      },
      get isFirstNameError() {
        if (self.firstNameError) {
            return true
        } else {
            return false
        }
      },
      get isLastNameError() {
        if (self.lastNameError) {
            return true
        } else {
            return false
        }
      },
      get isEmailError() {
        if (self.emailError) {
            return true
        } else {
            return false
        }
      },
  })