import { find } from "lodash"
import { getRoot } from "mobx-state-tree"

export const merchantProductsStoreViews = self => ({
  get token() {
    return getRoot(self).authStore.token
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get areThereAnyProductsInMenu() {
    if (self.allProducts === null || self.allProducts === 0){
        return false
    }
    else {
        return true
    }
  },
  get searchString() {
    if (self.searchInput === null ){
        return ''
    }
    else {
        return self.searchInput
    }
  },
  get selectedCategoryId() {
    if (self.selectedCategory === null ){
        return ''
    }
    else {
        return self.selectedCategory.id
    }
  },
  get productCount() {
    if (self.allProducts === null ){
        return 0
    }
    else {
        return self.allProducts
    }
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get isImportingMenu() {
    if (self.merchantLogs.length == 0) return false
    if (!self.merchantLogs[0].data) return true
    else return false
  },
  get lastImport() {
    const li = find(self.merchantLogs,  ml => ml.type === "IMPORT_PRODUCT" && ml.type !== null)
    return li
  },
  get isOrderAsc() {
    if (self.orderBy === null) return false
    return self.orderBy.split(',')[1] === 'ASC'
  },
  get isOrderDesc() {
    if (self.orderBy === null) return false
    return self.orderBy.split(',')[1] === 'DESC'
  },
  get isOrderingStock() {
    if (self.orderBy === null) return false
    return self.orderBy.split(',')[0] === 'STOCK'
  }
})