import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './feedbacksStoreActions'
import * as views from './feedbacksStoreViews'
import Feedback from "../../models/feedback/Feedback";

const FeedbacksStore = types
  .model('FeedbacksStore', {
    feedbacks: types.maybeNull(types.optional(
      types.array(types.reference(Feedback)), []
    )),

    loading: false,
  })
  .views(views.feedbacksStoreViews)
  .actions(actions.feedbacksStoreActions)

export default FeedbacksStore