import { getRoot } from "mobx-state-tree"
import { formatCurrency } from "../../../services/currency-service"

export const newOrderStoreViews = self => ({
  get token() {
    return getRoot(self).authStore.token
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get ordersFactory() {
    return getRoot(self).ordersFactory
  },
  get usersFactory() {
    return getRoot(self).usersFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get usersStore(){
    return getRoot(self).usersStore
  },
  get selectedMerchantId() {
    return self.selectedMerchant.id
  },
  get areProductsSelected() {
    if (self.selectedProducts !== null) {
      return true
    } else {
      return false
    }
  },
  get validateOrder() {
    if (self.areProductsSelected && self.areMerchantAndUserSelected && self.selectedLocation !== null) {
      return true
    } else {
      return false
    }
  },
  get isProductSelectedInModal() {
    if (self.selectedProduct !== null) {
      return true
    } else {
      return false
    }
  },
  get hasProductInModalAllInformation() {
    if (self.selectedProduct !== null && self.selectedProduct.quantity !== null) {
      return true
    } else {
      return false
    }
  },
  get areMerchantAndUserSelected() {
    if (self.selectedMerchant && self.selectedUser) {
      return true
    } else {
      return false
    }
  },
  get subtotalOrder() { 
    if (self.selectedProducts !== null) {
      let subtotal = 0
      self.selectedProducts.forEach(product => {
        subtotal += product.basePrice * product.quantity
      })
      return subtotal
    } else {
      return 0
    }
  },
  get subtotalOrderFormated() {
    return formatCurrency(self.subtotalOrder)
  },
  get totalOrderTax() { 
    if (self.selectedProducts !== null) {
      let tax = 0
      self.selectedProducts.forEach(product => {
        tax += (product.tax * product.basePrice) * product.quantity
      })
      return tax
    } else {
      return 0
    }
  },
  get totalOrderTaxFormated() { 
    return formatCurrency(self.totalOrderTax)
  },
  get totalOrderFormated() {
    return formatCurrency(self.subtotalOrder + self.totalOrderTax)
  },
  get customerFirstName() {
    if (self.selectedUser?.firstName !== null) {
      return self.selectedUser.firstName + "-" + self.selectedUser.email
    }
    return "Unknown"
  }
  
})