import { types } from "mobx-state-tree"
import Merchant from "../../models/merchant/Merchant"
import * as actions from './merchantsFactoryActions'
import * as views from './merchantsFactoryViews'

const MerchantsFactory = types
  .model('MerchantsFactory', {
    merchants: types.optional(
      types.array(Merchant), []
    )
  })
  .views(views.merchantsFactoryViews)
  .actions(actions.merchantsFactoryActions)

export default MerchantsFactory