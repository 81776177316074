import { api_get_merchants } from "../../../queries/merchants"

export const merchantsStoreActions = self => ({
  async getMerchants() {
    try {
        self.set('loading', true)
        const res = await api_get_merchants(self.token)

        if (res.error) throw res

        if (res.count !== 0) {
        self.set('allMerchantsCount', res.data.count)
        }
        const ids = await self.merchantsFactory.addUpdateMerchants(res.data.rows)
        self.set('merchants', ids)
        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
},
  set(key, value) {
    self[key] = value
  }
})