import { api_get_drivers, api_delete_driver, api_get_driver_tasks, api_post_driver } from "../../../queries/drivers"
import { toJS } from "mobx"
import { ERRORS } from "../../../constants/strings"
import { parsePhoneNumber, isValidNumber } from 'libphonenumber-js';
import { toastToSuccess, toastToError } from "../../../services/notification-service";
import { ToasterMsgs } from "../../../constants/toasterMsgs";

export const editDriverStoreActions = self => ({   
  async getDriver(driverId) {
    self.clearDriver()
    await self.driversStore.getDrivers()
    const driver = self.driversStore.drivers.find(driver => driver.id === parseInt(driverId))
    if (driver) {
      self.set('selectedDriver', driver.id)
      self.setDriver()
      self.getDriverTasks()
    } else {
      self.set('selectedDriver', null)
    }
  },
  setDriver() {
    self.set('firstName', self.selectedDriver.firstName)
    self.set('lastName', self.selectedDriver.lastName)
    self.set('username', self.selectedDriver.username)
    self.set('email', self.selectedDriver.email)
    self.set('role', self.selectedDriver.role)
    self.set('phone', self.selectedDriver.isThereinternationalPhoneNumber ? self.selectedDriver.internationalPhoneNumber : null)
    self.set('countryPrefix', self.selectedDriver.countryPrefix)
    if (self.selectedDriver.merchant) self.set('selectedMerchant', self.selectedDriver.merchant)
    if (self.selectedDriver.paymentMethods !== null && self.selectedDriver?.paymentMethods.length > 0) {
      self.selectedDriver.paymentMethods.map(x => self.populatePaymentMethod(x));
    }
  },
  populatePaymentMethod(x){
    self.set('businessName', x.businessName)
    self.set('taxId', x.taxId)
    self.set('abaRoutingNumber', x.routingNumber)
    self.set('beneficiary', x.beneficiary)
    self.set('bankAddress', x.bankAddress)
  },
  clearDriver() {
    self.set('firstName', null)
    self.set('lastName', null)
    self.set('email', null)
    self.set('role', null)
    self.set('phone', null)
    self.set('countryPrefix', null)
    self.set('businessName', null)
    self.set('taxId', null)
    self.set('abaRoutingNumber', null)
    self.set('beneficiary', null)
    self.set('bankAddress', null)
    self.set('selectedMerchant', null)
  },
  clearErrors() {
    self.set('firstNameError', null)
    self.set('lastNameError', null)
    self.set('emailError', null)
  },
  async registerDriver() {
    try {
      self.set('loading', true)

      const data = {
        user: {
          firstName: self.firstName,
          lastName: self.lastName,
          email: self.email,
          countryPrefix: "+" + self.countryPrefix,
          phone: self.phone.substring(self.countryPrefix.length),
        },
        payoutInformation: {
          businessName: self.businessName,
          taxId: self.taxId,
          routingNumber: self.abaRoutingNumber,
          beneficiary: self.beneficiary,
          bankAddress: self.bankAddress,
        },
        vehicleType: self.vehicleType,
        merchantId: self.selectedMerchant.id,
      }
      const res = await api_post_driver(data, self.token)

      if (res.error) throw res

      self.set('loading', false)
      toastToSuccess(ToasterMsgs.driverCreated)
      return res

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
  },
  async deleteDriver() {
    try {
      self.set('loading', true)
      const res = await api_delete_driver(self.token, self.selectedDriver.id)

      if (res.error) throw res

      self.usersFactory.addUpdateUsers(res.data.data)
      self.set('loading', false)
      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },
  async getDriverTasks() {
    try {
      self.set('loading', true)
      const res = await api_get_driver_tasks(self.token, self.selectedDriver.id)

      if (res.error) throw res

      const orderIds = self.ordersFactory.addUpdateOrders(res.data.data)
      self.set('selectedDriverOrders', orderIds)
      self.set('loading', false)
      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },
  validFirstName() {
    if (self.firstName === null || self.firstName === '' || self.firstName.length < 1) self.set('firstNameError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('firstNameError', null)
  },
  validLastName() {
    if (self.lastName === null || self.lastName === '' || self.lastName.length < 1) self.set('lastNameError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('lastNameError', null)
  },
  validEmail() {
    if (self.email === null || self.email === '' || self.email.length < 1) self.set('nameError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('emailError', null)
  },
  clearInputs() {
    self.set('firstNameError', null)
    self.set('lastNameError', null)
    self.set('emailError', null)
  },
  getCountryCode(phoneNumber){
    try {
      //const parsed = parsePhoneNumber(phoneNumber)

      if (isValidNumber(phoneNumber)) {
        const parsed = parsePhoneNumber(phoneNumber)
        return parsed.country.toLowerCase();
      } else {
        // Handle invalid phone number
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  getCountryCodeForDriver(){
    if (self.selectedDriver !== null && self.selectedDriver.internationalPhoneNumber !== null) {
      const countryCode = self.getCountryCode(self.selectedDriver.internationalPhoneNumber)
      return countryCode
    } else {
      return "us"
    }
  },

  set(key, value) {
    self[key] = value
  }
  })