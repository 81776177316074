import { types } from "mobx-state-tree"
import Category from "../../models/category/Category"
import * as actions from './categoriesFactoryActions'
import * as views from './categoriesFactoryViews'

const CategoriesFactory = types
  .model('CategoriesFactory', {
    categories: types.optional(
      types.array(Category), []
    )
  })
  .views(views.categoriesFactoryViews)
  .actions(actions.categoriesFactoryActions)

export default CategoriesFactory