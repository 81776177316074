import { getRoot } from "mobx-state-tree"

export const deliveryTasksFactoryViews = self => ({
    get usersFactory() {
        return getRoot(self).usersFactory
    },
    get merchantsFactory() {
        return getRoot(self).merchantsFactory
    },
    get ordersFactory() {
        return getRoot(self).ordersFactory
    }
})