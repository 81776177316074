import find from 'lodash/find'
import { api_get_merchant } from "../../../queries/merchants"
import { _add_update_objects } from '../../../services/factories-service'

export const merchantsFactoryActions = self => ({
  addUpdateMerchants(merchants) {
    return _add_update_objects(merchants, self.merchants, self.parseMerchant)
  },
  addUpdateMerchant(m) {
    const ids = self.addUpdateMerchants([m])
    return ids[0]
  },
  parseMerchant(m) {
    return ({
      ...m,
      openingHours: Array.isArray(m.openingHours) && m.openingHours.length ? self.openingHoursFactory.addUpdateOpeningHours(m.openingHours) : [],
      location: m.location?.id ? self.locationsFactory.addUpdateLocation(m.location) : null
    })
  },
  async getMerchant(mId) {
    try {
      const id = parseInt(mId)

      let merchant = find(self.merchants, m => m.id === id)
      if (merchant) return merchant

      const res = await self.fetch(id)
      if (res.error) throw res

      return find(self.merchants, m => m.id === id)
    } catch (err) {
      return {
        ...err,
        error: true
      }
    }
  },
  async fetch(id) {
    try {
      const res = await api_get_merchant(self.token, id)
      if (res.error) throw res

      self.addUpdateMerchant(res)

      return res
    } catch (err) {
      return err
    }
  },
})