import { toJS } from "mobx"
export const UserActions = self => ({
  update(user) {
    if (user.email)self.set('email', user.email)
    if (user.firstName) self.set('firstName', user.firstName)
    if (user.lastName) self.set('lastName', user.lastName)
    if (user.hashId) self.set('hashId', user.hashId)
    if (user.managingStore) self.set('managingStore', user.managingStore)
    if (user.merchant) self.set('merchant', user.merchant)
    if (user.role) self.set('role', user.role)
    if (user.status) self.set('status', user.status)
    if (user.locations) self.set('locations', user.locations)
    if (user.countryPrefix) self.set('countryPrefix', user.countryPrefix)
    if (user.phone) self.set('phone', user.phone)
    if (user.paymentMethods) self.set('paymentMethods', user.paymentMethods)
  },
  set(key, value) {
    self[key] = value
  }
})
