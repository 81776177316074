import { types } from 'mobx-state-tree'
import * as views from './holidayHoursStoreViews'
import * as actions from './holidayHoursStoreActions'
import HolidayHours from '../../models/holidayHours/HolidayHours'

const HolidayHoursStore = types
  .model('holidayHoursStore', {
    allHolidays: types.maybeNull(types.optional(
      types.array(types.reference(HolidayHours)), []
    )),
    selectedDate: types.maybeNull(types.reference(HolidayHours)),
    setOpenHour: types.maybeNull(types.string),
    setCloseHour: types.maybeNull(types.string),
    setClosed: types.maybeNull(types.boolean),
    setTitle: types.maybeNull(types.string),
    setDate: types.maybeNull(types.string),
    loading: false,

    setTitleError: types.maybeNull(types.string),
  })
  .views(views.holidayHoursStoreViews)
  .actions(actions.holidayHoursStoreActions)

export default HolidayHoursStore
