import { types } from 'mobx-state-tree'
import Category from '../../models/category/Category'
import * as views from './categoriesStoreViews'
import * as actions from './categoriesStoreActions'

const CategoriesStore = types
  .model('categoriesStore', {
    categories: types.maybeNull(types.optional(
      types.array(types.reference(Category)), []
    )),
    allCategories: types.maybeNull(types.number),
    updating: false,
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
  })
  .views(views.categoriesStoreViews)
  .actions(actions.categoriesStoreActions)

export default CategoriesStore
