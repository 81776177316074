
export const formatCurrency = (value) => {

  if (value === null) {
    return '$0.00'
  } else {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  }
};


export const convertToCents = (price) => {
  const numberPrice = Number(price);
  const cents = Math.round(numberPrice * 100);
  return cents;
}

export const convertToDollars = (cents) => {
  const numberCents = Number(cents);
  const dollars = numberCents / 100;
  return dollars;
};


