import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_simple_stats = async (merchantId, token) => {
  try {
    const res = await axios.get(`${API}/cms/merchant/${merchantId}/simple-stats`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


