import { getRoot } from "mobx-state-tree"

export const merchantViews = self => ({
  get homeStore() {
    return getRoot(self).homeStore
  },
  get fullNumberOfRatings() {
    let str = self.numberOfRatings === 1 ? " rating" : " ratings"
    return self.numberOfRatings + str
  },
  get open() {
    let dt = new Date()
    let oh = self.openingHours.find(oh => oh.dayOfTheWeek === dt.getDay())
    if (oh) {
      let chDate = new Date(oh.closeHour)
      let chHour = chDate.toLocaleString('en-US', 
        { hour: 'numeric', minute: 'numeric', hour12: true })
      return 'Open until ' + chHour
    }
    return 'Closed'
  },
  get deliveryFeeFormatted() {
    return self.deliveryFee ? self.deliveryFee + '$' : '5$'
  },
  get deliveryTimeFormatted() {
    return self.deliveryTime ? self.deliveryTime + 'min' : '30min'
  },
  get categories() {
    return self.activeCategories.length > 0 ? 
      self.activeCategories : self.homeStore.categories
  }
})