import { api_get_all_categories, api_get_all_categories_for_merchant } from "../../../queries/categories"
import { api_get_all_coupons } from "../../../queries/coupons"


export const couponsStoreActions = self => ({   
  async getCoupons() {
    try {
        self.set('loading', true)
        const res = await api_get_all_coupons(self.token)

        if (res.error) throw res

        const ids = await self.couponsFactory.addUpdateCoupons(res.data)
        self.set('coupons', ids)
        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
},
  set(key, value) {
    self[key] = value
  }
})