import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_system_settings = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/system-settings`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_put_system_settings = async (data, token) => {
  try {
    const res = await axios.put(`${API}/cms/system-settings`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


