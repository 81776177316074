import { api_get_user_by_id } from '../../../queries/users'
import { api_get_users_paginate } from '../../../queries/users'

export const usersStoreActions = self => ({   
    async getUser(username) {
        try {
          self.set('loading', true)
          const res = await api_get_user_by_id(username, self.token)
          if (res.error) throw res
          const userId = await self.usersFactory.addUpdateUser(res.data)
          self.set('selectedUser', userId)
          self.authStore.setRole()
          self.set('loading', false)  
          return res.data
  
        } catch (err) {
          self.set('loading', false)
          console.log(err)
        }
      },
      async getUsersPaginate(page, row) {
        try {
          self.set('loading', true)
          const res = await api_get_users_paginate(self.token, page, row)
  
          if (res.error) throw res
  
          if (res.count !== 0) {
            self.set('allUsers', res.count)
          }
          const ids = await self.usersFactory.addUpdateUsers(res.rows)
          self.set('users', [])
          self.set('users', ids)
          self.set('loading', false)
          return res.data
  
        } catch (err) {
          self.set('loading', false)
          self.handleErrors(err)
        }
      },
  
      handleErrors(err) {
        self.authStore.checkIfStillAuthorized(err)
      },
      set(key, value) {
        self[key] = value
      }
  })