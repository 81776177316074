import { getRoot } from "mobx-state-tree"
import { convertToTime, localTime, UTCTime } from "../../../services/time-service";
import dayjs from 'dayjs';
import { DateTime } from "luxon";
import DatePickerCustom from "../../../components/Input/DatePickerCustom";


export const openingHoursStoreViews = self => ({
  get openingHoursFactory() {
    return getRoot(self).openingHoursFactory
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get setOpenHourFormated() {
    console.log(self.setOpenHour)
    if (self.setOpenHour !== null) {
      return DateTime.fromJSDate(convertToTime(self.setOpenHour))
    }
  },
  get setCloseHourFormated() {
    if (self.setCloseHour !== null) {

      return DateTime.fromJSDate(convertToTime(self.setCloseHour))
    }
  },
  get isSetTitleError() {
    if (self.titleError) {
        return true
    } else {
        return false
    }
  },
})