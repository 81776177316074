import { types } from "mobx-state-tree"
import Merchant from "../../models/merchant/Merchant"
import Product from "../../models/product/Product"
import Category from "../../models/category/Category"
import * as views from './newOrderStoreViews'
import * as actions from './newOrderStoreActions'
import User from "../../models/user/User"
import Location from "../../models/location/Location"

const NewOrderStore = types
  .model('NewOrderStore', {
    selectedMerchant: types.maybeNull(
      types.reference(Merchant)
    ),
    selectedMerchantProducts: types.maybeNull(
      types.array(types.reference(Product)), []
    ),
    selectedProducts: types.maybeNull(
      types.array(types.reference(Product)), []
    ),
    selectedUser: types.maybeNull(
      types.reference(User)
    ),
    selectedProduct: types.maybeNull(
      types.reference(Product)
    ),
    selectedLocation: types.maybeNull(
      types.reference(Location)
    ),
    // searchedUsers: types.optional(
    //   types.array(types.reference(User)), []
    // ),

    loading: false,
  })
  .views(views.newOrderStoreViews)
  .actions(actions.newOrderStoreActions)

export default NewOrderStore