import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"

export const singleCouponViews = self => ({
  get fullPrice() {
    return 'US$' + self.quantity * self.product?.price
  },
  get valid() {
    self.validTitle();
    self.validCoupon();
    self.validMinimumOrderSize();
    self.validValue();
    return (
      self.titleError ||
      self.couponError ||
      self.minimumOrderSizeError ||
      self.valueError
    ) === null;
  },
  get isTitleError() {
    return self.titleError ? true : false;
  },
  get isCouponError() {
    return self.couponError ? true : false;
  },
  get isMinimumOrderSizeError() {
    return self.minimumOrderSizeError ? true : false;
  },
  get isValueError() {
    return self.valueError ? true : false;
  },
  get isFormFilledOut() {
    return (
      self.title !== null && self.title !== "" &&
      self.coupon !== null && self.coupon !== "" &&
      self.minimumOrderSize !== null && self.minimumOrderSize !== 0 &&
      self.value !== null && self.value !== 0
    );
  },
  
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantStore() {
    return getRoot(self).merchantStore
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get effectsFactory() {
    return getRoot(self).effectsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore?.id
  },
  get userId() {
    return getRoot(self).authStore.managingStore?.id
  },
})