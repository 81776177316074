import find from 'lodash/find'
import { toJS } from 'mobx'
import { api_get_category_by_id } from '../../../queries/categories'
import { _add_update_objects } from '../../../services/factories-service'

export const locationsFactoryActions = self => ({
  addUpdateLocations(locations) {

    return _add_update_objects(locations, self.locations, self.parseLocation)
  },
  addUpdateLocation(p) {
    const ids = self.addUpdateLocations([p])
    return ids[0]
  },
  parseLocation(p) {
    return ({
      ...p,
    })
  },
})