import { toJS } from "mobx"
import { api_get_all_categories } from "../../../queries/categories"
import { api_accept_order, api_get_order_by_id  } from "../../../queries/orders"
import { api_delete_order } from "../../../queries/orders"
import { toastToSuccess, toastToError } from "../../../services/notification-service"
import { ToasterMsgs } from "../../../constants/toasterMsgs"

export const singleOrderStoreActions = self => ({   
  async getOrder(id) {
    try {
        self.set('loading', true)
        const res = await api_get_order_by_id(id, self.token)

        if (res.error) throw res

        const orderId = await self.ordersFactory.addUpdateOrder(res.data.data)
        self.set('order', orderId)

        if (res.data.data.deliveryTasks[0].driverId) {
          self.getDriver(res.data.data.deliveryTasks[0].driverId)
        }

        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
        return err
    }
},
async getDriver(driverId) {
  self.set('loadingDrivers', true)
  await self.merchantDriversStore.getDrivers()
  const driver = self.driversStore.drivers.find(driver => driver.id === parseInt(driverId))
  if (driver) {
    self.set('driver', driver.id)
  } else {
    self.set('driver', null)
  }
  self.set('loadingDrivers', false)
},
async deleteOrder(orderId) {
  try {
    self.set('loading', true)
    const res = await api_delete_order(self.token, orderId)

    if (res.error) throw res

    const ids = await self.ordersFactory.addUpdateOrder(res.data.data)
    self.set('loading', false)

    self.getOrder(orderId)

    toastToSuccess(ToasterMsgs.successProductUpdate)
    return res.data

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async acceptOrder() {
  try {

    self.set("loading", true)

    const res = await api_accept_order(self.token, self.order.id)


    if (res.error) throw res

    // get order again

    await self.getOrder(self.order.id)

    self.set("loading", false)

    toastToSuccess(ToasterMsgs.successOrderAccepted)

  } catch (err) {
    self.set('loading', false)
    toastToError({title: "Could not accpet order 🥲. Drop us a message."})
    console.log(err)
  }
},
set(key, value) {
  self[key] = value
}
})