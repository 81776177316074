import { toJS } from "mobx"
import { api_get_products_by_merchant_id_all } from "../../../queries/products"
import { api_post_create_order } from "../../../queries/orders"
import { toastToSuccess, toastToError } from "../../../services/notification-service"
import { ToasterMsgs } from "../../../constants/toasterMsgs"

export const newOrderStoreActions = self => ({

  async getAllMerchantProducts() {
    try {
        self.set('loading', true)
        const res = await api_get_products_by_merchant_id_all(self.token, self.selectedMerchantId)

        if (res.error) throw res

        const ids = await self.productsFactory.addUpdateProducts(res.rows)
        self.set('selectedMerchantProducts', ids)
        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
  },
  setCustomer(customer){
    if (customer === null) {
      self.set('selectedUser', null)
    } else {
    const id = self.usersFactory.addUpdateUser(customer)
    self.set('selectedUser', id)
    }
  },
  resetNewOrderStore()  {
    self.set('selectedMerchant', null)
    self.set('selectedUser', null)
    self.set('selectedMerchantProducts', null)
    self.set('selectedProducts', null)
    self.set('selectedProduct', null)
    self.set('selectedLocation', null)
  },
  clearSelectedProductInModal() {
    self.set('selectedProduct', null)
  },
  setProductInModal(id) {
    self.set('selectedProduct', id)
    self.selectedProduct.set('quantity', 1)
  },
  pushProductToOrder() {
    if (self.selectedProducts === null) {
      self.set('selectedProducts', [])
    }
    if (!self.selectedProducts.includes(self.selectedProduct)) {
      self.selectedProducts.push(self.selectedProduct.id)
    }
  },
  removeProductFromOrder(id) {
    //find selectedProduct index in selectedProducts array and remove it from array
    const index = self.selectedProducts.indexOf(id)
    if (index > -1) {
      self.selectedProducts.splice(index, 1)
    }
  },
  async createOrder() {
    try {
      self.set('loading', true)

      const products = []
      self.selectedProducts.forEach((product) => {
        products.push({
          id: product.id,
          quantity: product.quantity
        })
      })

      const data = {
        buyerId: self.selectedUser?.id,
        deliveryLocationId: self.selectedLocation?.id,
        products: products
      }

      const res = await api_post_create_order(data, self.token)

      if (res.error) throw res

      await self.ordersFactory.addUpdateOrder(res.data)
      self.set('loading', false)
      toastToSuccess(ToasterMsgs.successOrderCreated)
      return res

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
},
  set(key, value) {
    self[key] = value
  }
})