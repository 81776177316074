import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_accounting_get_orders = async (
  token, 
  merchantId,
  query = null,
  dateRange = [],
  page, 
  limit
) => {

  let queryQ = query ? `&query=${query}` : ''
  let dateRangeQ = dateRange.length === 2 ? `&dateRange=${dateRange.join(",")}` : ''

  try {
    const res = await axios.get(`${API}/cms/accounting/${merchantId}/orders?items=${limit}&page=${page}${queryQ}${dateRangeQ}`, 
      {
        headers: {
          'Authorization': token
        }
      }
    )
    return res
  } catch (err) {
    return _throw(err)
  }
}
export const api_accounting_export_orders = async (
  token, 
  merchantId,
  query = null,
  dateRange = [],
) => {

  let queryQ = query ? `&query=${query}` : ''
  let dateRangeQ = dateRange.length === 2 ? `&dateRange=${dateRange.join(",")}` : ''

  try {
    const res = await axios.get(`${API}/cms/accounting/${merchantId}/orders-csv?items=${100000}&page=${1}${queryQ}${dateRangeQ}`, 
      {
        headers: {
          'Authorization': token
        }
      }
    )
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_order_amount_settled = async (data, token, merchantId, orderId) => {
  try {
    const res = await axios.post(`${API}/cms/accounting/settle-order/${merchantId}/${orderId}`, data,  {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_all_orders_settled = async (token, merchantId) => {
  try {
    const res = await axios.post(`${API}/cms/accounting/settle-orders/${merchantId}`, null, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}