export const merchantActions = self => ({
  update(m) {
    self.set('title', m.title)
    self.set('legalName', m.legalName)
    self.set('description', m.description)
    self.set('phone', m.phone)
    self.set('email', m.email)
    self.set('status', m.status)
    self.set('newOrderStatus', m.newOrderStatus)
    self.set('createdAt', m.createdAt)
    self.set('updatedAt', m.updatedAt)
    self.set('avgRating', m.avgRating)
    self.set('numberOfRatings', m.numberOfRatings)
    self.set('deliveryTime', m.deliveryTime)
    self.set('deliveryFee', m.deliveryFee)
    self.set('commission', m.commission)
    self.set('ordersCount', m.ordersCount)
    self.set('price', m.price)
    self.set('website', m.price)
    self.set('hero', m.hero) 
    self.set('openingHours', m.openingHours)
    self.set('location', m.location)
    self.set('deliveryCommision', m.deliveryCommision)
    self.set('pickupCommision', m.pickupCommision)
    self.set('deliveryRadius', m.deliveryRadius)
  },
  set(key, value) {
    self[key] = value
  }
})