
export const paymentMethodActions = self => ({
  update(r) {
    self.set('createdAt', r.createdAt)
    self.set('updatedAt', r.updatedAt)
    self.set('accountType', r.accountType)
    self.set('bankAddress', r.bankAddress)
    self.set('bankName', r.bankName)
    self.set('beneficiary', r.beneficiary)
    self.set('businessName', r.businessName)
    self.set('lastFour', r.lastFour)
    self.set('routingNumber', r.routingNumber)
    self.set('status', r.status)
    self.set('taxId', r.taxId)
    self.set('types', r.types)
  },
  set(key, value) {
    self[key] = value
  }
})