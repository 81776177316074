import { types } from "mobx-state-tree"
import * as actions from './paymentMethodsActions'
import * as views from './paymentMethodsViews'
import PaymentMethod from "../../models/paymentMethod/PaymentMethod"

const PaymentMethodFactory = types
  .model('PaymentMethodFactory', {
    paymentMethods: types.optional(
      types.array(PaymentMethod), []
    )
  })
  .views(views.paymentMethodsFactoryViews)
  .actions(actions.paymentMethodsFactoryActions)

export default PaymentMethodFactory