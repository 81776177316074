import { types } from "mobx-state-tree"
import Product from '../../models/product/Product'
import * as actions from './addUpdateProductStoreActions'
import * as views from './addUpdateProductStoreViews'
import Category from "../../models/category/Category"
import Asset from "../../models/asset/Asset"
import Effect from "../../models/effect/Effect"

const AddUpdateProductStore = types
  .model('AddUpdateProductStore', {
    sku: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    basePrice: types.maybeNull(types.number),
    tax: types.maybeNull(types.number),
    stock: types.maybeNull(types.number),
    thc: types.maybeNull(types.number),
    cbd: types.maybeNull(types.number),
    effects: types.maybeNull(
      types.array(types.reference(Effect)), []
    ),
    category: types.maybeNull(
      types.reference(Category)),
    asset: types.maybeNull(types.optional(Asset, {})),
    type: types.maybeNull(types.string),
  
    titleError: types.maybeNull(types.string),
    skuError: types.maybeNull(types.string),
    descriptionError: types.maybeNull(types.string),
    basePriceError: types.maybeNull(types.string),
    taxError: types.maybeNull(types.string),
    stockError: types.maybeNull(types.string),
    thcError: types.maybeNull(types.string),
    cbdError: types.maybeNull(types.string),
    assetError: types.maybeNull(types.string),

    status: types.maybeNull(types.string),
    selectedProduct: types.maybeNull(
      types.reference(Product)
    ),

    aiTitle: types.maybeNull(types.string),
    aiDescription: types.maybeNull(types.string),
    aiCategory: types.maybeNull(types.number),
    aiEffects: types.maybeNull(types.array(types.number)),
    aiStrain: types.maybeNull(types.string),
    aiThc: types.maybeNull(types.number),
    aiCbd: types.maybeNull(types.number),
    aiLoading: false,

    loading: false,
    loadingAsset: false,
    publishing: false
  })
  .views(views.addUpdateProductStoreViews)
  .actions(actions.addUpdateProductStoreActions)

export default AddUpdateProductStore

