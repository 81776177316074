import { types } from "mobx-state-tree"
import * as views from './merchantLogsViews'
import * as actions from './merchantLogsActions'

const MerchantLogs = types
  .model('MerchantLogs', {
    id: types.maybeNull(types.number),
    data: types.maybeNull(
      types.frozen()
    ),
    type: types.maybeNull(types.string),
    lastUpdated: types.maybeNull(types.string),
  })
  .views(views.merchantLogsViews)
  .actions(actions.merchantLogsActions)

export default MerchantLogs