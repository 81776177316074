import { useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  Popover,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { inject, observer } from 'mobx-react' 
import AlertDialog from '../../../../components/Modals/AlertDialog';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox(props) {

  const { authStore, accountStore } = props.store 
  const [openDialog, setOpenDialog] = useState(false);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    setOpenDialog(true);
  };

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const handleAgree = async () => {
    setOpenDialog(false);
    authStore.logout()
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <Avatar variant="rounded" alt={accountStore.cmsName} src={accountStore.user.avatar?.originUrl} /> */}
        <Avatar variant="rounded" alt={accountStore.cmsName} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{accountStore.cmsName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {accountStore.userRole}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={accountStore.cmsName} src={accountStore.user?.avatar?.originUrl} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{accountStore.cmsName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
            {accountStore.userRole}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        {/* {  accountStore.user.role === "ADMIN" &&
        <>
          <Divider sx={{ mb: 0 }} />
          <List sx={{ p: 1 }} component="nav">
            <ListItem
              button
              to="/dashboard/team"
              component={NavLink}
            >
              <BusinessTwoToneIcon fontSize="small" />
              <ListItemText primary="Team" />
            </ListItem>
          </List> 
        </>
        } */}
        <Divider sx={{ mb: 0 }} />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={() => logout()}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
          <AlertDialog 
            open={openDialog}
            handleDisagree={handleDisagree}
            handleAgree={handleAgree}
            title={"Log out?"}
            description={"Do you really want to log out?"}
          />
        </Box>
      </Popover>
    </>
  );
}

export default inject('store')(observer(HeaderUserbox));
