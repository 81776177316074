import { types } from "mobx-state-tree"
import * as actions from './effectsFactoryActions'
import * as views from './effectsFactoryViews'
import Effect from "../../models/effect/Effect"

const EffectsFactory = types
  .model('EffectsFactory', {
    effects: types.optional(
      types.array(Effect), []
    )
  })
  .views(views.effectsFactoryViews)
  .actions(actions.effectsFactoryActions)

export default EffectsFactory