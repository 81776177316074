import { types } from "mobx-state-tree"
import * as actions from './PaymentMethodActions'
import * as views from './PaymentMethodViews'

const PaymentMethod = types
  .model("PaymentMethod", {
    id: types.identifierNumber,
    accountType: types.maybeNull(types.string),
    bankAddress: types.maybeNull(types.string),
    bankName: types.maybeNull(types.string),
    beneficiary: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    lastFour: types.maybeNull(types.number),
    routingNumber: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    taxId: types.maybeNull(types.string),
    types: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .views(views.paymentMethodViews)
  .actions(actions.paymentMethodActions)

export default PaymentMethod