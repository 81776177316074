import { types } from "mobx-state-tree"
import * as actions from './storefrontActions'
import * as views from './storefrontViews'
import Asset from "../../models/asset/Asset"
import Merchant from "../../models/merchant/Merchant"

const StorefrontStore = types
  .model('StorefrontStore', {
    merchantStorefront: types.maybeNull(
      types.reference(Merchant)
    ),

    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),

    status: types.maybeNull(types.string),
    legalName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    taxId: types.maybeNull(types.string),
    licenceNumber: types.maybeNull(types.string),
    website: types.maybeNull(types.string),

    deliveryCommission: types.maybeNull(types.string),
    pickupCommission: types.maybeNull(types.string),

    asset: types.maybeNull(types.optional(Asset, {})),
      
    taxIdError: types.maybeNull(types.string),
    licenceNumberError: types.maybeNull(types.string),
    titleError: types.maybeNull(types.string),
    descriptionError: types.maybeNull(types.string),
    legalNameError: types.maybeNull(types.string),
    emailError: types.maybeNull(types.string),
    phoneError: types.maybeNull(types.string),
    deliveryCommissionError: types.maybeNull(types.string),
    pickupCommissionError: types.maybeNull(types.string),
    loading: false,
    loadingAsset: false,
  })
  .views(views.storefrontViews)
  .actions(actions.storefrontActions)

export default StorefrontStore

