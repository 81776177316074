import { _add_update_objects } from '../../../services/factories-service'

export const feedbacksFactoryActions = self => ({
  addUpdateFeedbacks(feedbacks) {
    return _add_update_objects(feedbacks, self.feedbacks, self.parseFeedback)
  },
  addUpdateFeedback(p) {
    const ids = self.addUpdateFeedbacks([p])
    return ids[0]
  },
  parseFeedback(f) {
    return ({
      ...f,
      user: f.user?.id ? self.usersFactory.addUpdateUser(f.user) : null,
      merchant: f.merchant?.id ? self.merchantsFactory.addUpdateMerchant(f.merchant) : null,
    })
  },
})