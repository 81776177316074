import { types } from "mobx-state-tree"
import * as views from './AssetViews'
import * as actions from './AssetActions'

const Asset = types
  .model('Asset', {
    id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    fileSize: types.maybeNull(types.number),
    fileType: types.maybeNull(types.string),
    originUrl: types.maybeNull(types.string),
    mimetype: types.maybeNull(types.string),
    uploadedType: types.maybeNull(types.string),
    poster: types.maybeNull(types.string),
    localUrl: types.maybeNull(types.string),

    // uploading
    loading: false,
    error: types.maybeNull(types.string),
    progress: 0
  })
  .views(views.assetViews)
  .actions(actions.assetActions)

export default Asset