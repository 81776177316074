import { types } from "mobx-state-tree"
import * as actions from './locationsFactoryActions'
import * as views from './locationsFactoryViews'
import Location from "../../models/location/Location"

const LocationsFactory = types
  .model('LocationsFactory', {
    locations: types.optional(
      types.array(Location), []
    )
  })
  .views(views.locationsFactoryViews)
  .actions(actions.locationsFactoryActions)

export default LocationsFactory