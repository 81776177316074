import { getRoot } from "mobx-state-tree"

export const ordersFactoryViews = self => ({
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get usersFactory() {
    return getRoot(self).usersFactory
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get locationsFactory() {
    return getRoot(self).locationsFactory
  },
  get deliveryTasksFactory() {
    return getRoot(self).deliveryTasksFactory
  },
  get feedbacksFactory() {
    return getRoot(self).feedbacksFactory
  },
  get couponsFactory() {
    return getRoot(self).couponsFactory
  }
})