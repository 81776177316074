import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';
import ProtectedRouteMerchant from './routes/ProtectedRouteMerchant';
import ProtectedRouteSupportOrAdmin from './routes/ProtectedRouteSupportOrAdmin';
import ProtectedOnlyAdmin from './routes/ProtectedOnlyAdmin';


const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


// Dashboards

const Login = Loader(lazy(() => import('./content/pages/Login/LoginScreen')));
const RegisterStepOne = Loader(lazy(() => import('./content/pages/Register/RegisterScreen')));
const RegisterStepTwo = Loader(lazy(() => import('./content/pages/RegisterStepTwo/RegisterScreen')));

// Applications

const Users = Loader(lazy(() => import('./content/applications/allUsers')));
const Menu = Loader(lazy(() => import('./content/applications/menu')));
const Orders = Loader(lazy(() => import('./content/applications/orders')));
const AddItem = Loader(lazy(() => import('./content/applications/addItem')));
const ImportCsv = Loader(lazy(() => import('./content/applications/importCsv')));
const EditItem = Loader(lazy(() => import('./content/applications/editItem')));
const Categories = Loader(lazy(() => import('./content/applications/categories')));
const ProductsInCategory = Loader(lazy(() => import('./content/applications/productsInCategory')));
const WorkingHours = Loader(lazy(() => import('./content/applications/workingHours')));
const EditWorkingHour = Loader(lazy(() => import('./content/applications/editWorkingHour')));
const HolidayHours = Loader(lazy(() => import('./content/applications/holidayHours')));
const AddHolidayHour = Loader(lazy(() => import('./content/applications/editHolidayHour')));
const SingleOrder = Loader(lazy(() => import('./content/applications/singleOrder')));
const Storefront = Loader(lazy(() => import('./content/applications/storefront')));
const Settings = Loader(lazy(() => import('./content/applications/settings')));
const Team = Loader(lazy(() => import('./content/applications/team')));
const SingleTeamMember = Loader(lazy(() => import('./content/applications/singleTeamMember')));
const Feedback = Loader(lazy(() => import('./content/applications/feedback')));
const Overview = Loader(lazy(() => import('./content/applications/overview')));
const Accounting = Loader(lazy(() => import('./content/applications/accounting')));
const MerchantDrivers = Loader(lazy(() => import('./content/applications/merchantDrivers')));
const SingleMerchantDriver = Loader(lazy(() => import('./content/applications/editMerchantDriver')));

// Support Applications

const Merchants = Loader(lazy(() => import('./content/support/allMerchants')));
const AllOrders = Loader(lazy(() => import('./content/support/allOrders')));
const SingleOrderSupport = Loader(lazy(() => import('./content/support/singleOrderSupport')));
const Drivers = Loader(lazy(() => import('./content/support/drivers')));
const EditDriver = Loader(lazy(() => import('./content/support/editDriver')));
const NewDriver = Loader(lazy(() => import('./content/support/newDriver')));
const NewOrderSupport = Loader(lazy(() => import('./content/support/newOrderSupport')));
const SystemSettings = Loader(lazy(() => import('./content/support/systemSettings')));
const StoreFrontSupport = Loader(lazy(() => import('./content/support/storefrontSupport')));
const AllUsers = Loader(lazy(() => import('./content/support/allUsers')));
const Effects = Loader(lazy(() => import('./content/support/effects')));
const AddSingleEffect = Loader(lazy(() => import('./content/support/addSingleEffect')));
const EditSingleEffect = Loader(lazy(() => import('./content/support/editSingleEffect')));
const AdminCategories = Loader(lazy(() => import('./content/support/categories')));
const EditSingleCategory = Loader(lazy(() => import('./content/support/editSingleCategory')));
const AddSingleCategory = Loader(lazy(() => import('./content/support/addSingleCategory')));
const Promotions = Loader(lazy(() => import('./content/support/promotions')));
const AddSingleCoupon = Loader(lazy(() => import('./content/support/addSingleCoupon')));
const EditSingleCoupon = Loader(lazy(() => import('./content/support/editSingleCoupon')));
const AddNotification = Loader(lazy(() => import('./content/support/addSingleNotification')));

// Status

const Status404 = Loader(lazy(() => import('./content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('./content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('./content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('./content/pages/Status/Maintenance')));
const NoAccess= Loader(lazy(() => import('./content/pages/Status/NoAccess')));


const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      {
        path: 'off-limits',
        element: <NoAccess />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <RegisterStepOne />
      },
      {
        path: 'complete-registration',
        element: <RegisterStepTwo />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ] 
  },
  {
    path: 'dashboard',
    element: (
      <ProtectedRouteMerchant>
        <SidebarLayout />
      </ProtectedRouteMerchant>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/dashboard/menu"
            replace
          />
        )
      },
      {
        path: 'users',
        element: (
            <Users />
        ),
      },
      {
        path: 'menu',
        element: (
            <Menu />
        ),
      },
      {
        path: 'menu/add-item',
        element: (
            <AddItem />
        ),
      },
      {
        path: 'menu/import-csv',
        element: (
            <ImportCsv />
        ),
      },
      {
        path: 'menu/:id',
        element: <EditItem />
      },
      {
        path: 'categories',
        element: (
            <Categories />
        ),
      },
      {
        path: 'categories/:id',
        element: <ProductsInCategory />
      },
      {
        path: 'working-hours',
        element: (
            <WorkingHours />
        ),
      },
      {
        path: 'working-hours/:id',
        element: (
            <EditWorkingHour />
        ),
      },
      {
        path: 'holiday-hours',
        element: (
            <HolidayHours />
        ),
      },
      {
        path: 'holiday-hours/add',
        element: (
            <AddHolidayHour />
        ),
      },
      {
        path: 'holiday-hours/:id',
        element: (
            <AddHolidayHour />
        ),
      },
      {
        path: 'orders',
        element: (
            <Orders />
        ),
      },
      {
        path: 'orders/:id',
        element: (
            <SingleOrder />
        ),
      },
      {
        path: 'storefront',
        element: <Storefront />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'team',
        element: <Team />
      },
      {
        path: 'team/:id',
        element: <SingleTeamMember />
      },
      {
        path: 'feedback',
        element: <Feedback />
      },
      {
        path: 'overview',
        element: <Overview />
      },
      {
        path: 'accounting',
        element: <Accounting />
      },
      {
        path: 'merchant-drivers',
        element: <MerchantDrivers />
      },
      {
        path: 'merchant-drivers/:id',
        element: <SingleMerchantDriver />
      },
    ]
  },
  {
    path: 'support',
    element: (
      <ProtectedRouteSupportOrAdmin>
        <SidebarLayout />
      </ProtectedRouteSupportOrAdmin>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/support/merchants"
            replace
          />
        )
      },
      {
        path: 'merchants',
        element: (
            <Merchants />
        ),
      },
      {
        path: 'merchants/:id',
        element: (
            <StoreFrontSupport />
        ),
      },
      {
        path: 'orders',
        element: (
            <AllOrders />
        ),
      },
      {
        path: 'orders/:id',
        element: (
            <SingleOrderSupport />
        ),
      },
      {
        path: 'add-effect',
        element: (
            <AddSingleEffect />
        ),
      },
      {
        path: 'add-category',
        element: (
            <AddSingleCategory />
        ),
      },
      {
        path: 'effects/:id',
        element: (
            <EditSingleEffect />
        ),
      },
      {
        path: 'categories/:id',
        element: (
            <EditSingleCategory />
        ),
      },
      {
        path: 'settings',
        element: (
          <ProtectedOnlyAdmin>
            <SystemSettings />
          </ProtectedOnlyAdmin>
        ),
      },
      {
        path: 'drivers',
        element: (
            <Drivers />
        ),
      },
      {
        path: 'drivers/:id',
        element: (
            <EditDriver />
        ),
      },
      {
        path: 'add-driver',
        element: (
            <NewDriver />
        ),
      },
      {
        path: 'effects',
        element: (
            <Effects />
        ),
      },
      {
        path: 'categories',
        element: (
            <AdminCategories />
        ),
      },
      {
        path: 'promotions',
        element: (
            <Promotions />
        ),
      },
      {
        path: 'add-coupon',
        element: (
            <AddSingleCoupon />
        ),
      },
      {
        path: 'promotions/:id',
        element: (
            <EditSingleCoupon />
        ),
      },
      {
        path: 'send-notification',
        element: (
            <AddNotification />
        ),
      },
      {
        path: 'new-order',
        element: (
            <NewOrderSupport />
        ),
      },
      {
        path: 'users',
        element: (
            <AllUsers />
        ),
      },
    ]
  },

  
];

export default routes;
