import { getRoot } from "mobx-state-tree"

export const accountingStoreViews = self => ({   
    get token() {
        return getRoot(self).authStore.token
      },
      get merchantId() {
        return getRoot(self).authStore.managingStore.id
      },
      get ordersFactory() {
        return getRoot(self).ordersFactory
      },
  })