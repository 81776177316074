import { types } from "mobx-state-tree"
import * as actions from './settingsActions'
import * as views from './settingsViews'
import Asset from "../../models/asset/Asset"
import Merchant from "../../models/merchant/Merchant"

const SettingsStore = types
  .model('SettingsStore', {
    merchantStorefront: types.maybeNull(
      types.reference(Merchant)
    ),

    status: types.maybeNull(types.string),
    legalName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    notificationEmail: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    taxId: types.maybeNull(types.string),
    licenceNumber: types.maybeNull(types.string),

    deliveryCommission: types.maybeNull(types.string),
    pickupCommission: types.maybeNull(types.string),

    deliveryRadius: types.maybeNull(types.string),
    lat: types.maybeNull(types.string),
    lng: types.maybeNull(types.string),

    newLat: types.maybeNull(types.number),
    newLng: types.maybeNull(types.number),

    title: types.maybeNull(types.string),
    streetNumber: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    floor: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    post: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
      
    taxIdError: types.maybeNull(types.string),
    licenceNumberError: types.maybeNull(types.string),
    legalNameError: types.maybeNull(types.string),
    emailError: types.maybeNull(types.string),
    phoneError: types.maybeNull(types.string),
    deliveryCommissionError: types.maybeNull(types.string),
    pickupCommissionError: types.maybeNull(types.string),
    realTimeDeliveryEstimation: types.optional(types.boolean, true),
    loading: false,
    loadingAsset: false,
  })
  .views(views.settingsViews)
  .actions(actions.settingsActions)

export default SettingsStore

