import { types } from "mobx-state-tree"
import Product from "../../models/product/Product"
import * as actions from './ordersFactoryActions'
import * as views from './ordersFactoryViews'
import Order from "../../models/order/Order"

const OrdersFactory = types
  .model('OrdersFactory', {
    orders: types.optional(
      types.array(Order), []
    )
  })
  .views(views.ordersFactoryViews)
  .actions(actions.ordersFactoryActions)

export default OrdersFactory