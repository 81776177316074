import { getRoot } from "mobx-state-tree"

export const merchantsStoreViews = self => ({
  get token() {
    return getRoot(self).authStore.token
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get merchantsFactory() {
    return getRoot(self).merchantsFactory
  },
  get merchantStoreTitle() {
    if (self.selectedMerchant !== null) {
      return self.selectedMerchant.title
    } else {
      return "merchant"
    }
  }
})