import { map } from "lodash"
import { IMPORT_STATE } from "./importMenuStore"
import { api_import_menu } from "../../../queries/products"
import { toJS } from "mobx"

export const importMenuStoreActions = self => ({   
  importLocally(file, headers, separator) {
    
    self.set('file', file)

    self.set('separator', separator)

    self.set('headers', map(headers, header => ({
      originalName: header,
      mappedTo: '',
      active: false
    })))

    self.set('state', IMPORT_STATE.MAPPING)
  },

  async import() {
    try {
      self.set('loading', true)

      const data = new FormData()

      data.append('file', self.rawFile)
      data.append('csvHeaders', JSON.stringify(toJS(self.headers)))
      data.append('csvSeparator', self.separator)

      const res = await api_import_menu(self.token, data, self.rootStore.authStore.managingStore.id)

      self.set('state', IMPORT_STATE.COMPLETE)

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  resetHeadersWithValue(header) {
    self.headers.map(h => {
      if (h.mappedTo === header) {
        h.setActiveFalse()
        h.set('mappedTo', '')
      }
    })
  },
  
  set(key, value) {
    self[key] = value
  }
})