import { types } from "mobx-state-tree"
import * as actions from './OrderLogActions'
import * as views from './OrderLogViews'

const OrderLog = types
  .model("OrderLog", {
    id: types.identifierNumber,
    type: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
  })
  .views(views.orderLogViews)
  .actions(actions.orderLogActions)

export default OrderLog