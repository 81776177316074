import { types } from "mobx-state-tree"
import * as actions from './deliveryTasksFactoryActions'
import * as views from './deliveryTasksFactoryViews'
import Feedback from "../../models/feedback/Feedback"
import DeliveryTask from "../../models/deliveryTask/DeliveryTask"

const DeliveryTasksFactory = types
  .model('DeliveryTasksFactory', {
    deliveryTasks: types.optional(
      types.array(DeliveryTask), []
    )
  })
  .views(views.deliveryTasksFactoryViews)
  .actions(actions.deliveryTasksFactoryActions)

export default DeliveryTasksFactory