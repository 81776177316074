import { _add_update_objects } from '../../../services/factories-service'

export const deliveryTasksFactoryActions = self => ({
  addUpdateDeliveryTasks(deliveryTasks) {
    return _add_update_objects(deliveryTasks, self.deliveryTasks, self.parseDeliveryTask)
  },
  addUpdateDeliveryTask(p) {
    const ids = self.addUpdateDeliveryTasks([p])
    return ids[0]
  },
  parseDeliveryTask(f) {
    return ({
      ...f,
      //user: f.user?.id ? self.usersFactory.addUpdateUser(f.user) : null,
      //merchant: f.merchant?.id ? self.merchantsFactory.addUpdateMerchant(f.merchant) : null,
    })
  },
})