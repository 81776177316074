import { types } from "mobx-state-tree"
import Category from '../category/Category'
import Asset from '../asset/Asset'
import * as views from './ProductViews'
import * as actions from './ProductActions'
import Effect from "../effect/Effect"

const Product = types
  .model("Product", {
    id: types.identifierNumber,
    sku: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    basePrice: types.maybeNull(types.number),
    tax: types.maybeNull(types.number),
    stock: types.maybeNull(types.number),
    thc: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    cbd: types.maybeNull(types.number),
    type: types.maybeNull(types.string),
    effects: types.maybeNull(
      types.array(types.reference(Effect)), []
    ),
    category: types.maybeNull(
      types.reference(Category)),
    city: types.maybeNull(types.string),
    post: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    hero: types.maybeNull(Asset),
    assets: types.maybeNull(
      types.array(Asset)),
    status: types.maybeNull(types.string),
    isEditorsPick: types.maybeNull(types.boolean),
  })
  .views(views.productViews)
  .actions(actions.productActions)

export default Product