
export const UserViews = self => ({
  get numberPrefix() {
    if (self.countryPrefix === null) {
      return null
    } else {
      return self.countryPrefix
    }
  },
  get localPhoneNumber() {
    if (self.phoneNumber === null) {
      return null
    } else {
      return self.phone
    }
  },

  get internationalPhoneNumber() {
    if (self.numberPrefix !== null && self.localPhoneNumber !== null) {
      return self.numberPrefix + self.localPhoneNumber
    } else {
      return null
    }
  },

  get isThereinternationalPhoneNumber() {
    if (self.internationalPhoneNumber === null) {
      return false
    } else {
      return true
    }
  },
  get fullName() {
    if (self.firstName !== null ) {
      return self.firstName + ' ' + self.lastName 
    } else {
      return true
    }
  },

})