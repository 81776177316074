import { api_get_feedbacks } from "../../../queries/feedbacks"
import { toJS } from "mobx"
export const feedbacksStoreActions = self => ({  

  async getFeedbacks() {
    try {
      self.set('loading', true)
      const res = await api_get_feedbacks(self.token, self.merchantId)

      if (res.error) throw res
      const ids = await self.feedbacksFactory.addUpdateFeedbacks(res.ratings)
      self.set('feedbacks', ids)
      self.set('loading', false)
      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },
  set(key, value) {
    self[key] = value
  }

  })