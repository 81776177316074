export const ERRORS = {
  EMAIL_ERROR: "Email is invalid.",
  PASS_ERROR: 'Minimum five characters and one number required.',
  USERNAME_ERROR: 'Your username may only contain letters, numbers, and underscores.',
  NAME_ERROR: 'Name too short.',
  ITEM_TITLE_ERROR: 'Title too short.',
  ITEM_DESCRIPTION_ERROR: 'Description too short.',
  ITEM_BASE_PRICE_ERROR: 'Set a price.',
  ITEM_TAX_RATE_ERROR: 'Set a tax rate.',
  ITEM_THC_ERROR: 'Set the THC ammount.',
  ITEM_CBD_ERROR: 'Set the CBD ammount.',
  ITEM_AVAILABLE_ERROR: 'Set your stock.',
  ASSET_ERROR: 'Upload a product picture.',
}