import { getRoot } from "mobx-state-tree"
import { PRODUCT_ATTRIBUTES_HEADERS } from "../../../constants/types"
import { IMPORT_STATE } from "./importMenuStore"


export const importMenuStoreViews = self => ({

  get rootStore() {
    return getRoot(self)
  },
  get token() {
    return self.rootStore.authStore.token
  },

  get numberOfMappingsSelected() {
    return self.headers.filter(header => header.active).length
  },

  get validMapping() {
    if (!self.isSkuMapped) return false
    return true
  },

  get isSkuMapped() {
    return self.headers.filter(header => header.mappedTo === PRODUCT_ATTRIBUTES_HEADERS.SKU && header.active).length > 0
  },

  get willBeDrafted() {

    const activeHeaders = self.headers.filter(header => header.active)

    const numberOfHeadersMapped = activeHeaders.length

    if (numberOfHeadersMapped > 2) return true

    const headers = activeHeaders.map(header => header.mappedTo)

    if (headers.includes(PRODUCT_ATTRIBUTES_HEADERS.SKU) && headers.length === 1) return false

    if (headers.includes(PRODUCT_ATTRIBUTES_HEADERS.SKU) && headers.includes(PRODUCT_ATTRIBUTES_HEADERS.STOCK)) return false
    else return true
    
  },

  get stateTitle() {
    switch (self.state) {
      case IMPORT_STATE.UPLOAD:
        return "Upload File"
      case IMPORT_STATE.MAPPING:
        return "Map Headers"
      case IMPORT_STATE.IMPORTING:
        return "Importing"
      case IMPORT_STATE.COMPLETE:
        return "Complete"
      default:
        return "Upload File"
    }
  }

})