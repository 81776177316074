import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"

export const singleOrderStoreViews = self => ({
  get categoriesFactory() {
    return getRoot(self).categoriesFactory
  },
  get ordersFactory() {
    return getRoot(self).ordersFactory
  },
  get driversStore() {
    return getRoot(self).driversStore
  },
  get token() {
    return getRoot(self).authStore.token
  },
  get merchantId() {
    return getRoot(self).authStore.managingStore.id
  },
  get merchantDriversStore() {
    return getRoot(self).merchantDriversStore
  },
  get orderStatus() {
    if (self.order === null ){
        return ''
    }
    else {
        return self.order.status
    }
  },
  get orderTypeLabel() {
    if (self.order === null ){
        return null
    }
    else {
        return self.order.deliveryTypeLabel
    }
  },
  get customerAddress() {
    if (self.order === null ){
        return ''
    }
    else {
        return self.order.deliveryLocation?.address
    }
  },
  get isThereDeliveryLocation() {
    if (self.order?.deliveryLocation === null ){
        return false
    }
    else {
        return true
    }
  },

  get customerFirstName() {
    if (self.order === null ){
        return ''
    }
    else {
        return self.order.buyer?.firstName + ' ' + self.order.buyer?.lastName
    }
  },
  get driverFirstName() {
    if (self.order === null ){
        return ''
    }
    else {
        return self.driver?.firstName + ' ' + self.driver?.lastName
    }
  },
  get customerLastName() {
    if (self.order === null){
        return ''
    }
    else {
      if (self.order.buyer?.name === null){
        return ''
      } else {
        return self.order.buyer?.name
      }
    }
  },
  get isThereOrder() {
    if (self.order === null){
      if (self.order?.products === null){
        return false
      }
      return false
    }
    else {
        return true
    }
  },
  get areThereProducts() {
    if (self.order.products === null){
      return false
    }
    else {
        return true
    }
  },
  get isThereOrderLog() {
    if (self.order?.logs === null){
      return false
    }
    else {
        return true
    }
  },
  get isThereInvoiceLink() {
    if (self.order?.invoicelink === null){
      return false
    }
    else {
        return true
    }
  },
  get isThereOrderReview() {
    if (self.order?.rating !== null){
        return true
      } else {
        return false
      }
  },
  get areThereStars() {
    if (self.order?.rating !== null){
      if (self.order?.rating?.stars !== null) {
        return true
      } else {
        return false
      }
    }
  },
  get isThereTextReview() {
    if (self.isThereOrderReview && self.order?.rating?.text !== null){
        return true
    } else {
      return false
    }
  },
  get textReview() {
    if (self.isThereOrderReview && self.order?.rating?.text !== null){
        return self.order?.rating?.text
    } else {
      return "No review"
    }
  },
  get customerRating() {
    const stars = self.order?.rating?.stars;
    return stars !== undefined ? stars : null; 
  },
  get isOrderAccepted() {
    return self.order?.status !== "PAID"
  }
})