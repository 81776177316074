import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import TakeoutDiningTwoToneIcon from '@mui/icons-material/TakeoutDiningTwoTone';
import MopedTwoToneIcon from '@mui/icons-material/MopedTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';

const support = {
    heading: 'Support',
    items: [
      {
        name: 'Merchants',
        icon: StorefrontTwoToneIcon,
        link: '/support/merchants'
      },
      // {
      //   name: 'Drivers',
      //   icon: MopedTwoToneIcon,
      //   link: '/support/drivers'
      // },
      // {
      //   name: 'Orders',
      //   icon: TakeoutDiningTwoToneIcon,
      //   link: '/support/orders'
      // },
      // {
      //   name: 'Users',
      //   icon: PeopleAltTwoToneIcon,
      //   link: '/support/users'
      // },
      {
        name: 'Effects',
        icon: StarRateTwoToneIcon,
        link: '/support/effects'
      },
      {
        name: 'Categories',
        icon: CategoryTwoToneIcon,
        link: '/support/categories'
      },
      // {
      //   name: 'System Settings',
      //   icon: SettingsTwoToneIcon,
      //   link: '/support/settings'
      // },
    ]
  }

export default support;
