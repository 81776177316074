import { types } from "mobx-state-tree"
import * as actions from './HolidayHoursActions'
import * as views from './HolidayHoursViews'

const HolidayHours = types
  .model("HolidayHours", {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    openHour: types.maybeNull(types.string),
    closeHour: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    closed: types.maybeNull(types.boolean),
  })
  .views(views.holidayHoursViews)
  .actions(actions.holidayHoursActions)

export default HolidayHours