import { toJS } from "mobx"
import { formatCurrency } from "../../../services/currency-service"

export const productViews = self => ({
  get displayPrice() {
    return formatCurrency(self.basePrice)
  },
  get price() {
    return formatCurrency(self.basePrice)
  },
  get shortenedDescription() {
    return self.description?.substring(0, 30) + "..."
  },
  get imageUrl() {
     if (self.assets === null || self.assets.length === 0) {
      return "false"
    } else {
      return self.assets[0].originUrl
    }
  },
  get statusLabel() {
    return self.status
  },
  get statusColor() {
    switch (self.status) {
      case 'ACTIVE':
        return 'success'
      case 'DRAFT':
        return 'warning'
      default:
        return null
    }
  }
})