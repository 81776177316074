import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'

export const api_get_opening_hours = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/opening-hours/${id}`, {
      headers: {
        'Authorization': token 
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_holiday_hours = async (token, id) => {
  try {
    const res = await axios.get(`${API}/cms/holidays/${id}`, {
      headers: {
        'Authorization': token 
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_opening_hours = async (data, token, dateId) => {
  try {
    const res = await axios.put(`${API}/cms/opening-hours/${dateId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_holiday_hours = async (data, token, dateId) => {
  try {
    const res = await axios.put(`${API}/cms/holiday/${dateId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_create_holiday = async (data, token, dateId) => {
  try {
    const res = await axios.post(`${API}/cms/holiday/${dateId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}