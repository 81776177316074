import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';

const AlertDialog = (props) => {


  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleDisagree()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { props.showDisagreeOption &&
            <Button color={props.disagreeButtonColor ? props.disagreeButtonColor : "error"} onClick={() => props.handleDisagree()}>{props.alternativeNameForDisagreeButton || "Disagree"}</Button>
          }
          <Button color={props.agreeButtonColor ? props.agreeButtonColor : 'primary'} onClick={() => props.handleAgree()} autoFocus>
            {props.alternativeNameForAgreeButton ? props.alternativeNameForAgreeButton : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog