import { api_get_team_users , api_get_user_by_id, api_delet_user, api_update_team_user } from "../../../queries/users"


export const teamMemberStoreActions = self => ({   
  async getTeamMember(userId) {
    try {
      self.set('loading', true)
      const res = await api_get_team_users(self.token, self.merchantId)

      if (res.error) throw res

      const Ids = await self.usersFactory.addUpdateUsers(res.data)

      self.set('selectedTeamMember', userId)
      self.setTeamMember()

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  async getMerchantTeam() {
    try {
      self.set('loading', true)
      const res = await api_get_team_users(self.token, self.merchantId)

      if (res.error) throw res


      const ids = await self.usersFactory.addUpdateUsers(res.data)
      self.set('teamUsers', [])
      self.set('teamUsers', ids)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },
  setTeamMember() {
    self.set('firstName', self.selectedTeamMember.firstName)
    self.set('name', self.selectedTeamMember.name)
    self.set('username', self.selectedTeamMember.username)
    self.set('email', self.selectedTeamMember.email)
    self.set('role', self.selectedTeamMember.role)
  },
  set(key, value) {
    self[key] = value
  }
  })