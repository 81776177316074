import { types } from 'mobx-state-tree'
import * as views from './openingHoursStoreViews'
import * as actions from './openingHoursStoreActions'
import OpeningHours from "../../models/opening-hours/OpeningHours"

const OpeningHoursStore = types
  .model('openingHoursStore', {
    allDaysInWeek: types.maybeNull(types.optional(
      types.array(types.reference(OpeningHours)), []
    )),
    selectedDate: types.maybeNull(types.reference(OpeningHours)),
    setOpenHour: types.maybeNull(types.string),
    setCloseHour: types.maybeNull(types.string),
    setClosed: types.maybeNull(types.boolean),
    setTitle: types.maybeNull(types.string),
    loading: false,

    setTitleError: types.maybeNull(types.string),
  })
  .views(views.openingHoursStoreViews)
  .actions(actions.openingHoursStoreActions)

export default OpeningHoursStore
