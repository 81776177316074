import find from 'lodash/find'
import { toJS } from 'mobx'
import { api_get_category_by_id } from '../../../queries/categories'
import { _add_update_objects } from '../../../services/factories-service'

export const holidayHoursFactoryActions = self => ({
  addUpdateHolidayHours(holidayHours) {

    return _add_update_objects(holidayHours, self.holidayHours, self.parseHolidayHour)
  },
  addUpdateHolidayHour(p) {
    const ids = self.addUpdateHolidayHours([p])
    return ids[0]
  },
  parseHolidayHour(p) {
    return ({
      ...p,
    })
  },
})