import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRouteSupportOrAdmin = (props) => {

  const { authStore, accountStore } = props.store
  
  if (!authStore.checkIfUserIsStillLegit()) return <Navigate to="/login" replace={true} />
  if (!authStore.isAuthenticated) return <Navigate to="/login" replace={true} />
  if (!accountStore.isUserAdminOrSupport) return <Navigate to="/off-limits" replace={true} />

  return props.children
}

export default inject('store')(observer(ProtectedRouteSupportOrAdmin))


