import { types } from "mobx-state-tree"
import * as actions from './singleCategoryActions'
import * as views from './singleCategoryViews'
import Asset from "../../models/asset/Asset"
import Effect from "../../models/effect/Effect"
import Category from "../../models/category/Category"

const SingleCategoryStore = types
  .model('SingleCategoryStore', {
    selectedCategory: types.maybeNull(
      types.reference(Category)
    ),

    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),

    asset: types.maybeNull(types.optional(Asset, {})),
    icon: types.maybeNull(types.optional(Asset, {})),
      
    titleError: types.maybeNull(types.string),
    descriptionError: types.maybeNull(types.string),
    loading: false,
    loadingIcon: false,
    loadingAsset: false,
    loadingIconAsset: false,
  })
  .views(views.singleCategoryViews)
  .actions(actions.singleCategoryActions)

export default SingleCategoryStore

