import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './editDriverStoreActions'
import * as views from './editDriverStoreViews'
import DeliveryTask from "../../models/deliveryTask/DeliveryTask";
import Order from "../../models/order/Order";
import Merchant from "../../models/merchant/Merchant";

const EditDriverStore = types
  .model('EditDriverStore', {
    selectedDriver: types.maybeNull(
      types.reference(User)
    ),
    selectedDriverOrders: types.maybeNull(
      types.array(types.reference(Order))
    ),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    countryPrefix: types.maybeNull(types.string),
    vehicleType: types.maybeNull(types.string),
    selectedMerchant: types.maybeNull(
      types.reference(Merchant)),
    businessName: types.maybeNull(types.string),
    taxId: types.maybeNull(types.string),
    abaRoutingNumber: types.maybeNull(types.string),
    beneficiary: types.maybeNull(types.string),
    bankAddress: types.maybeNull(types.string),

    firstNameError: types.maybeNull(types.string),
    lastNameError: types.maybeNull(types.string),
    usernameError: types.maybeNull(types.string),
    emailError: types.maybeNull(types.string),
    loading: false,
  })
  .views(views.editDriverStoreViews)
  .actions(actions.editDriverStoreActions)

export default EditDriverStore