import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx"
import Asset from "../../models/asset/Asset"
import { api_post_product, api_get_product_by_id, api_update_product, } from "../../../queries/products"
import { api_get_merchant_storefront, api_update_merchant_storefront } from "../../../queries/merchants"
import { ToasterMsgs } from "../../../constants/toasterMsgs"
import { toastToError, toastToSuccess } from "../../../services/notification-service"
import { parse } from "date-fns"
import { api_post_effect, api_update_effect } from "../../../queries/effects"

export const singleEffectActions = self => ({
  resetProduct(id) {
    self.set('product', id)
  },
  validTitle() {
    if (self.title === null || self.title === '' || self.title.length < 1) self.set('titleError', ERRORS.ITEM_TITLE_ERROR) 
    else self.set('titleError', null)
  },
  validDescription() {
    if (self.description === null || self.description.length < 1) self.set('descriptionError', ERRORS.ITEM_DESCRIPTION_ERROR)
    else self.set('titleError', null)
  },
  clearInputs() {
    self.set('titleError', null)
    self.set('descriptionError', null)
  },
  setProductForEdit() {
    self.set('title', self.selectedProduct.title)
    self.set('description', self.selectedProduct.description)
    self.createAndUpdateAsset(self.selectedProduct.assets)
  },
  createAndUpdateIcon(newAsset) {
    if (newAsset !== null ) {
    const asset = Asset.create()
    self.set('icon', asset)
    self.icon.update(newAsset)
    } else {
      return false
    }
  },
  createAndUpdateAsset(newAsset) {
    if (newAsset !== null) {
    const asset = Asset.create()
    self.set('asset', asset)
    self.asset.update(newAsset)
    } else {
      return false
    }
  },
  async updateStorefront() {
    try {
      self.set('loading', true)

      const dataMerchant = {
        title: self.title,
        description: self.description,
        legalName: self.legalName,
        phone: self.phone,
        email: self.email,
        heroPictureId: self.asset.id,
        taxId: self.taxId,
        licenceNumber: self.licenceNumber,
      }
      const dataAdmin = {
        title: self.title,
        description: self.description,
        legalName: self.legalName,
        phone: self.phone,
        email: self.email,
        heroPictureId: self.asset.id,
        taxId: self.taxId,
        licenceNumber: self.licenceNumber,
        deliveryCommission: self.deliveryCommission,
      }


      const res = await api_update_merchant_storefront(self.accountStore.isUserAdminOrSupport ? dataAdmin : dataMerchant, self.token, self.merchantStorefront.id)

      if (res.error) throw res
      toastToSuccess(ToasterMsgs.successStorefrontUpdated)
      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
      toastToError(ToasterMsgs.genericError)
      console.log(err)
    }
  
},
async addEffect() {
  try {
    self.set('loading', true)

    const data = {
      title: self.title,
      description: self.description,
      coverId: self.asset?.id,
      iconId: self.icon?.id
    }
    const res = await api_post_effect(data, self.token)

    if (res.error) throw res

    const ids = await self.effectsFactory.addUpdateEffects([res.data])
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successProductCreated)
    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
async updateEffect() {
  try {
    self.set('loading', true)

    const data = {
      title: self.title,
      description: self.description,
      coverId: self.asset.id,
      iconId: self.icon.id
    }
    const res = await api_update_effect(data, self.selectedEffect?.id, self.token)

    if (res.error) throw res

    const ids = await self.effectsFactory.addUpdateEffects([res.data.data])
    self.set('loading', false)
    toastToSuccess(ToasterMsgs.successProductCreated)
    return res

  } catch (err) {
    self.set('loading', false)
    toastToError(ToasterMsgs.genericError)
    console.log(err)
  }
},
  async uploadAsset(file, preview) {
    self.set('loadingAsset', true)
    try {
      const asset = Asset.create()
      self.set('asset', asset)

      const res = await asset.upload(
        self.token,
        file,
        preview,
        'MERCHANT_HERO',
        "534"
      )

      if (res.error) throw res

      self.set('loadingAsset', false)
      return res.data
    } catch (err) {
      self.set('loadingAsset', false)
      console.log(err)
    }
  },

  async uploadIconAsset(file, preview) {
    self.set('loadingIconAsset', true)
    try {
      const asset = Asset.create()
      self.set('icon', asset)

      const res = await asset.upload(
        self.token,
        file,
        preview,
        'MERCHANT_HERO',
        "23456"
      )

      if (res.error) throw res

      self.set('loadingIconAsset', false)
      return res.data
    } catch (err) {
      self.set('loadingIconAsset', false)
      console.log(err)
    }
  },
  async getAndEditStorefront(merchantId) {
    try {
      self.set('loading', true)
      const res = await api_get_merchant_storefront(self.token, merchantId)

      if (res.error) throw res

      const ids = await self.merchantsFactory.addUpdateMerchant(res)

      self.clearInputs()
      self.set('merchantStorefront', ids)
      self.setStorefrontForEdit(res)

      self.set('loading', false)
      return res.data

    } catch (err) {
      self.set('loading', false)
    }
  },
  set(key, value) {
    self[key] = value
  },
  setEffectForEdit(effect) {
    self.set('selectedEffect', effect)
    self.set('title', self.selectedEffect.title)
    self.set('description', self.selectedEffect.description)
    self.createAndUpdateIcon(self.selectedEffect.icon)
    self.createAndUpdateAsset(self.selectedEffect.cover)
  },
  cleanForm() {
    self.set('title', null)
    self.set('description', null)
    self.set('asset', null)
    self.set('icon', null)

    self.clearInputs()
  },
})