import { api_get_all_categories, api_get_all_categories_for_merchant } from "../../../queries/categories"


export const categoriesStoreActions = self => ({   
  async getCategories() {
    try {
        self.set('loading', true)
        const res = await api_get_all_categories(self.token)

        if (res.error) throw res

        if (res.data.count !== 0) {
        self.set('allCategories', res.data.count)
        }
        const ids = await self.categoriesFactory.addUpdateCategories(res.data.rows)
        self.set('categories', ids)
        self.set('loading', false)
        return res.data

    } catch (err) {
        self.set('loading', false)
        console.log(err)
    }
},
async getCategoriesForMerchant() {
  try {
      self.set('loading', true)
      const res = await api_get_all_categories_for_merchant(self.token, self.merchantId)

      if (res.error) throw res

      if (res.data.count !== 0) {
      self.set('allCategories', res.data.count)
      }
      const ids = await self.categoriesFactory.addUpdateCategories(res.data.rows)
      self.set('categories', ids)
      self.set('loading', false)
      return res.data

  } catch (err) {
      self.set('loading', false)
      console.log(err)
  }
},
  set(key, value) {
    self[key] = value
  }
})