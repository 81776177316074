import { types } from "mobx-state-tree"
import * as actions from './openingHoursFactoryActions'
import * as views from './openingHoursFactoryViews'
import OpeningHours from "../../models/opening-hours/OpeningHours"

const OpenHoursFactory = types
  .model('OpeningHoursFactory', {
    openingHours: types.optional(
      types.array(OpeningHours), []
    )
  })
  .views(views.openingHoursFactoryViews)
  .actions(actions.openingHoursFactoryActions)

export default OpenHoursFactory